import { message, notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';
import qs from 'qs';
import slug from 'slug';

import {
  ENDPOINTS,
  COMPONENT_TYPE,
  SYSTEM_FIELD_PATH,
  SYSTEM_FIELD_KEY,
  SYSTEM_FIELD_NAME,
  SYSTEM_FIELD_STATUS,
  SYSTEM_FIELD_ORDER_ID,
  WORK_ITEM_TEST_RUN_ID,
  SYSTEM_FIELD_ASSIGN_TO,
  SYSTEM_FIELD_CREATED_BY,
  SYSTEM_FIELD_TEST_STEPS,
  WORK_ITEM_TEST_RESULT_ID,
  SYSTEM_FIELD_LATEST_RESULT,
  SYSTEM_FIELD_TEST_STEP_RESULT,
  SYSTEM_FIELD_TEST_CASE_VERSION,
  OPERATION_VALUE_REGEX
} from '../../../../constants';
import { Http, handleError } from '../../../../core';
import {
  removeDuplicate,
  generateTestStepKey,
  checkIsNotEmptyObject,
  getPrePathLinkToTicket,
  buildQueryForFilterMongo
} from '../../../../common/utils';

/**
 * Get new query select
 */
const getNewQuerySelect = select => {
  const oldSelect = typeof select == 'string' ? select : '';
  const selectArr = oldSelect.split(' ');
  let newSelect = oldSelect;

  const hasTestCaseVersion = selectArr.includes(SYSTEM_FIELD_TEST_CASE_VERSION);
  const hasLatestResult = selectArr.includes(SYSTEM_FIELD_LATEST_RESULT);

  if (!selectArr.includes(SYSTEM_FIELD_KEY)) {
    newSelect = `${newSelect} ${SYSTEM_FIELD_KEY}`;
  }

  if (!selectArr.includes(SYSTEM_FIELD_CREATED_BY)) {
    newSelect = `${newSelect} ${SYSTEM_FIELD_CREATED_BY}`;
  }

  if (!selectArr.includes(SYSTEM_FIELD_ASSIGN_TO)) {
    newSelect = `${newSelect} ${SYSTEM_FIELD_ASSIGN_TO}`;
  }

  if (hasTestCaseVersion) {
    newSelect = newSelect
      .split(' ')
      .filter(refName => !new RegExp(`^${SYSTEM_FIELD_TEST_CASE_VERSION}\.`, 'g').test(refName))
      .join(' ');
  } else {
    if (!selectArr.includes(`${SYSTEM_FIELD_TEST_CASE_VERSION}.${SYSTEM_FIELD_KEY}`)) {
      newSelect = `${newSelect} ${SYSTEM_FIELD_TEST_CASE_VERSION}.${SYSTEM_FIELD_KEY}`;
    }

    if (!selectArr.includes(`${SYSTEM_FIELD_TEST_CASE_VERSION}.${SYSTEM_FIELD_NAME}`)) {
      newSelect = `${newSelect} ${SYSTEM_FIELD_TEST_CASE_VERSION}.${SYSTEM_FIELD_NAME}`;
    }
  }

  if (
    selectArr.includes(`${SYSTEM_FIELD_TEST_CASE_VERSION}.${SYSTEM_FIELD_TEST_STEPS}`) &&
    !selectArr.includes(`${SYSTEM_FIELD_LATEST_RESULT}.${SYSTEM_FIELD_TEST_STEP_RESULT}`)
  ) {
    newSelect = `${newSelect} ${SYSTEM_FIELD_LATEST_RESULT}.${SYSTEM_FIELD_TEST_STEP_RESULT}`;
  }

  if (hasLatestResult) {
    newSelect = newSelect
      .split(' ')
      .filter(refName => !new RegExp(`^${SYSTEM_FIELD_LATEST_RESULT}\.`, 'g').test(refName))
      .join(' ');
  } else {
    if (!selectArr.includes(`${SYSTEM_FIELD_LATEST_RESULT}.${SYSTEM_FIELD_KEY}`)) {
      newSelect = `${newSelect} ${SYSTEM_FIELD_LATEST_RESULT}.${SYSTEM_FIELD_KEY}`;
    }
  }

  return newSelect;
};

/**
 * Handle get basic field list
 */
const handleGetBasicFieldList = ({ fieldListData, ticketListData }) => {
  const newFieldList = [...fieldListData[WORK_ITEM_TEST_RUN_ID]].map(item => {
    const newItem = { ...item };

    if (item?.refName === SYSTEM_FIELD_PATH) {
      newItem.name = i18next.t('akaat:workItem.testPlanning');
    }

    if (item?.refName === SYSTEM_FIELD_STATUS) {
      newItem.data = {
        ...newItem.data,
        ...ticketListData?.[WORK_ITEM_TEST_RUN_ID]?.workFlow
      };

      newItem.workTicketId = WORK_ITEM_TEST_RUN_ID;
    } else if (item?.refName === SYSTEM_FIELD_LATEST_RESULT) {
      newItem.data = {
        ...newItem.data,
        ...ticketListData?.[WORK_ITEM_TEST_RESULT_ID]?.workFlow,
        workTicketId: WORK_ITEM_TEST_RESULT_ID
      };

      newItem.workTicketId = WORK_ITEM_TEST_RESULT_ID;
    } else {
    }

    return newItem;
  });

  return newFieldList;
};

/**
 * Handle return sub field relation
 */
const convertSubRelationField = (rootField, list = [], workFlow, workTicketName) => {
  const relationField = [];

  list.forEach(fieldRelation => {
    relationField.push({
      ...fieldRelation,
      isInsert: false,
      data: fieldRelation.data ? fieldRelation.data : { ...workFlow },
      refName: `${rootField?.refName}.${fieldRelation.refName}`,
      name: i18next.exists(`akaat:workItem.${fieldRelation.refName}`)
        ? `${workTicketName || rootField?.name} - ${i18next.t(`akaat:workItem.${fieldRelation.refName}`)}`
        : `${workTicketName || rootField?.name} - ${fieldRelation.name}`,
      workTicketId: rootField?.lookup?.workTicketId,
      refNameParent: rootField?.refName,
      isRelationDisplayField: rootField?.data.displayField === fieldRelation.refName,
      prePath: getPrePathLinkToTicket({ workTicketId: rootField?.lookup?.workTicketId })
    });
  });

  return relationField;
};

/**
 * Handle get field list for relation
 */
const handleGetFieldListForRelation = async ({
  field,
  fieldListData,
  ticketListData,
  processList,
  helpers,
  callback
}) => {
  if (fieldListData[field?.lookup?.workTicketId]) {
    const workTicketName = processList?.find(p => p?.workTicketId === field?.lookup?.workTicketId)?.name;
    const list = convertSubRelationField(
      field,
      fieldListData[field?.lookup?.workTicketId],
      ticketListData?.[field?.lookup?.workTicketId]?.workFlow,
      workTicketName
    );

    callback(list);
  } else if (!Object.keys(fieldListData).includes(field?.lookup?.workTicketId)) {
    const res = await helpers.getStoreActions()?.global?.getTicketTypeById(field?.lookup?.workTicketId);

    fieldListData[field?.lookup?.workTicketId] = res?.fields;

    const workTicketName = processList?.find(p => p?.workTicketId === field?.lookup?.workTicketId)?.name;
    const list = convertSubRelationField(
      field,
      res?.fields,
      ticketListData?.[field?.lookup?.workTicketId]?.workFlow,
      workTicketName
    );

    callback(list);
  } else {
  }
};

export const model = {
  /**
   * State
   */
  fieldConfig: null,
  fieldList: [],
  allSubFieldList: [],
  fieldRelationList: [],
  data: null,
  total: 0,
  query: {},
  selectedRows: [],
  selectedRowKeys: [],
  expandedRowKeys: [],
  editingTestRun: null,
  summaryData: null,
  dataAddedTestCasesList: null,
  collapseKeys: [
    'anchorDetails',
    'anchorExecution',
    'anchorRelatedTickets',
    'anchorActivities',
    'anchorPeople',
    'anchorDates',
    'anchorAutomation'
  ], // For collapse/expand boxes on detail form
  activeActivityTab: '',
  activeExecutionActivityTab: 'attachments',
  loadingList: false,
  loadingItem: false,
  loadingTestPlanItemSummary: false,
  resetView: false,
  fullScreen: false,

  /**
   * Action: Set field config
   */
  setFieldConfig: action((state, payload) => {
    if (state?.fieldConfig === undefined) {
      return;
    }

    if (!checkIsNotEmptyObject(payload)) {
      state.fieldConfig = null;
    }

    state.fieldConfig = payload;
  }),

  /**
   * Action: Set test run field list contain field relation
   */
  setAllSubFieldList: action((state, payload) => {
    if (state.allSubFieldList === undefined || payload === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.data = [];
      return;
    }

    state.allSubFieldList = payload;
  }),

  /**
   * Action: Set test run list
   */
  setTestRunList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) {
      return;
    }

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    let newData = [];

    if (payload.forceSetList) {
      newData = [...payload.rows];
    } else {
      newData = [...payload.rows].map((item, index) => {
        const testcaseVersion = item?.[SYSTEM_FIELD_TEST_CASE_VERSION];

        let testStepResults = item?.[SYSTEM_FIELD_LATEST_RESULT]?.[SYSTEM_FIELD_TEST_STEP_RESULT];
        testStepResults = Array.isArray(testStepResults) && testStepResults.length ? [...testStepResults] : [];

        let testSteps = testcaseVersion?.[SYSTEM_FIELD_TEST_STEPS];
        testSteps = Array.isArray(testSteps) && testSteps.length ? [...testSteps] : [];

        testSteps = testSteps.map((s, idx) => {
          const stepResult = testStepResults?.find(sub => sub?.[SYSTEM_FIELD_ORDER_ID] === s?.[SYSTEM_FIELD_ORDER_ID]);

          return {
            ...s,
            key: generateTestStepKey(item?.key),
            [SYSTEM_FIELD_ORDER_ID]: idx + 1,
            [SYSTEM_FIELD_STATUS]: stepResult?.status
          };
        });

        return {
          ...item,
          xNumber: index + 1,
          [SYSTEM_FIELD_TEST_CASE_VERSION]: { ...testcaseVersion, [SYSTEM_FIELD_TEST_STEPS]: testSteps }
        };
      });
    }

    state.data = newData;
    state.total = payload.count;
  }),

  /**
   * Action: Set total
   */
  setTotal: action((state, payload) => {
    if (state?.total === undefined) {
      return;
    }

    state.total = payload;
  }),

  /**
   * Action: Set test run field list
   */
  setFieldList: action((state, payload) => {
    if (state.fieldList === undefined || payload === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.data = [];
      return;
    }

    state.fieldList = payload;
  }),

  /**
   * Action: Set relation field list
   */
  setFieldRelationList: action((state, payload) => {
    if (state.fieldRelationList === undefined || payload === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.fieldRelationList = [];
      return;
    }

    state.fieldRelationList = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) {
      return;
    }

    state.query = payload;
  }),

  /**
   * Action: Set selected row
   */
  setSelectedRows: action((state, payload) => {
    if (state?.selectedRows === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.selectedRows = [];
      return;
    }

    state.selectedRows = payload;
  }),

  /**
   * Action: Set selected row keys
   */
  setSelectedRowKeys: action((state, payload) => {
    if (state?.selectedRowKeys === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.selectedRowKeys = [];
      return;
    }

    state.selectedRowKeys = payload;
  }),

  /**
   * Action: Set reset view
   */
  setResetView: action((state, payload) => {
    if (state?.resetView === undefined) {
      return;
    }

    state.resetView = payload;
  }),

  /**
   * Action: Set full screen
   */
  setFullScreen: action((state, payload) => {
    if (state?.fullScreen === undefined) {
      return;
    }

    state.fullScreen = payload;
  }),

  /**
   * Action: Set editing test run
   */
  setEditingTestRun: action((state, payload) => {
    if (state?.editingTestRun === undefined) {
      return;
    }

    state.editingTestRun = payload;
  }),

  /**
   * Action: Set summary data
   */
  setSummaryData: action((state, payload) => {
    if (state?.summaryData === undefined) {
      return;
    }

    if (!(Array.isArray(payload?.summary) && payload?.summary.length)) {
      state.summaryData = null;
      return;
    }

    state.summaryData = payload;
  }),

  /**
   * Action: Set collapse keys
   */
  setCollapseKeys: action((state, payload) => {
    if (state?.collapseKeys === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.collapseKeys = [];
      return;
    }

    state.collapseKeys = payload;
  }),

  /**
   * Action: Set active activity tab
   */
  setActiveActivityTab: action((state, payload) => {
    if (state?.activeActivityTab === undefined) {
      return;
    }

    state.activeActivityTab = payload;
  }),

  /**
   * Action: Set active execution activity tab
   */
  setActiveExecutionActivityTab: action((state, payload) => {
    if (state?.activeExecutionActivityTab === undefined) {
      return;
    }

    state.activeExecutionActivityTab = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) {
      return;
    }

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) {
      return;
    }

    state.loadingItem = payload;
  }),

  /**
   * Action: Set loading test plan item summary
   */
  setLoadingTestPlanItemSummary: action((state, payload) => {
    if (state?.loadingTestPlanItemSummary === undefined) {
      return;
    }

    state.loadingTestPlanItemSummary = payload;
  }),

  /**
   * Action: Set expanded row keys
   */
  setExpandedRowKeys: action((state, payload) => {
    if (state?.expandedRowKeys === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.expandedRowKeys = [];
      return;
    }

    state.expandedRowKeys = payload;
  }),

  /**
   * Action: Set Added testcases list
   */
  setAddedTestCasesList: action((state, payload) => {
    if (!Array.isArray(payload?.keyExsited)) {
      state.dataAddedTestCasesList = [];
      return;
    }

    state.dataAddedTestCasesList = payload.keyExsited;
  }),

  /**
   * Action: Call api to get test run list
   */
  getTestRunList: thunk(async (action, payload, helpers) => {
    const newPayload = { ...payload };
    const { noSetDataToStore } = newPayload;
    delete newPayload.noSetDataToStore;

    try {
      if (!noSetDataToStore) {
        action.setLoadingList(true);
        action.setQuery(payload);
      }

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;

      if (!newPayload?.filter?.path) {
        newPayload.filter = {
          ...newPayload?.filter,
          path: {
            [OPERATION_VALUE_REGEX]: `${slug(globalTenant?.tenantKey)}/${slug(globalProject?.projectKey)}/`,
            $options: 'si'
          }
        };
      }

      newPayload.select = getNewQuerySelect(newPayload.select);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }${ENDPOINTS.TEST_PLAN_TEST_RUN}/list?${buildQueryForFilterMongo(newPayload)}`;
      const res = await Http.get(url).then(res => res.data);

      if (!noSetDataToStore) {
        action.setTestRunList(res?.data);
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      if (!noSetDataToStore) {
        action.setLoadingList(false);
      }
    }
  }),

  /**
   * Action: Call api to get test run summary
   */
  getTestRunSummary: thunk(async (action, payload, helpers) => {
    try {
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }${ENDPOINTS.TEST_PLAN_TEST_RUN_SUMMARY}?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setSummaryData(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to get test run children summary
   */
  getTestRunChildrenSummary: thunk(async (action, payload, helpers) => {
    try {
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;

      const children = Array.isArray(payload) && payload.length ? [...payload] : [];
      const res = {
        data: []
      };

      for (let i = 0; i < children.length; i++) {
        const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${
          globalProject?.projectKey
        }${ENDPOINTS.TEST_PLAN_TEST_RUN_SUMMARY}?${buildQueryForFilterMongo(children[i])}`;
        const res2 = await Http.get(url).then(res => res.data);

        if (res2?.data) {
          res.data.push({ ...res2?.data, folderName: children[i]?.folderName });
        } else {
          res.data.push(null);
        }
      }

      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to get test run by key
   */
  getTestRunByKey: thunk(async (action, payload, helpers) => {
    const newPayload = { ...payload };
    const noSetDataToStore = newPayload?.noSetDataToStore;
    delete newPayload.noSetDataToStore;

    try {
      if (!payload) {
        throw new Error('Get test run by key: No test run key');
      }

      if (!noSetDataToStore) {
        action.setLoadingItem(true);
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_RUN}/${newPayload?.key}`;
      const res = await Http.get(url).then(res => res.data);

      if (!noSetDataToStore) {
        action.setEditingTestRun(res?.data);
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      if (!noSetDataToStore) {
        action.setLoadingItem(false);
      }
    }
  }),

  /**
   * Action: Call api to update test run
   */
  updateTestRun: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload?.key || Array.isArray(payload?.body))) {
        throw new Error('Update test run: Invalid test run payload');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { body, noShowSuccessMessage } = newPayload;
      delete newPayload.noShowSuccessMessage;
      const newBody = Array.isArray(body) ? body : [newPayload];

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_RUN}`;
      const res = await Http.put(url, newBody).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:testPlan.messageUpdatingTestRun'),
          description: i18next.t('akaat:message.updatedSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update multi test run
   */
  updateMultiTestRun: thunk(async (action, payload, helpers) => {
    try {
      if (!Array.isArray(payload?.body)) {
        throw new Error('Update test run: Invalid test run payload');
      }

      action.setLoadingList(true);

      const newPayload = { ...payload };
      const { body, noShowSuccessMessage } = newPayload;
      delete newPayload.noShowSuccessMessage;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_RUN}/mutiple`;
      const res = await Http.put(url, body).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:common.success'),
          description: i18next.t('akaat:message.yourChangesHasBeenSaved')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to delete test run
   */
  deleteTestRun: thunk(async (action, payload, helpers) => {
    try {
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;

      if (!globalProject?.projectKey || !globalTenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { keys, noShowSuccessMessage } = newPayload;
      delete newPayload.noShowSuccessMessage;

      const body = {
        data: keys // Example: keys is ["6", "8", "99"]
      };

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_RUN}`;
      const res = await Http.delete(url, body).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:testPlan.messageDeletingTestRun'),
          description: i18next.t('akaat:message.deletedSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update status test run
   */
  updateStatusTestRun: thunk(async (action, payload, helpers) => {
    try {
      if (!(Array.isArray(payload?.body) && payload?.body.length)) {
        throw new Error('Update status test run: Invalid test run payload');
      }

      const newPayload = { ...payload };
      const { body, noShowSuccessMessage } = newPayload;
      delete newPayload.noShowSuccessMessage;

      action.setLoadingItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_RUN}/status`;
      const res = await Http.put(url, body).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:common.success'),
          description: i18next.t('akaat:message.yourChangesHasBeenSaved')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to get test plan item summary
   */
  getTestPlanItemSummary: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingTestPlanItemSummary(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }${ENDPOINTS.TEST_PLAN_TEST_RUN}/summary?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTestPlanItemSummary(false);
    }
  }),

  /**
   * Action: Get all test run key list
   */
  getAllTestRunKeyList: thunk(async (action, payload, helpers) => {
    try {
      const newPayload = { ...payload };
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }${ENDPOINTS.TEST_PLAN_TEST_RUN}/all?${buildQueryForFilterMongo(newPayload)}`;
      const res = await Http.get(url).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
    }
  }),

  /**
   * Action: Call api to execute test run
   */
  executeTestRun: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.key) {
        throw new Error('Execute test run: No key');
      }

      action.setLoadingItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_RUN}/execute/one`;
      const res = await Http.put(url, payload).then(res => res.data);

      message.success(i18next.t('akaat:message.executedSuccessfully'));

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to execute all test run
   */
  executeAllTestRun: thunk(async (action, payload, helpers) => {
    try {
      if (!Array.isArray(payload) || !payload.length) {
        message.warning(i18next.t('akaat:message.nothingNewChangesLeftToUpdate'));
        return;
      }

      action.setLoadingItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_RUN}/execute`;
      const res = await Http.put(url, payload).then(res => res.data);

      message.success(i18next.t('akaat:message.executedSuccessfully'));

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: On change collapse
   */
  onChangeCollapse: thunk(async (action, payload, helpers) => {
    if (!payload) {
      throw new Error('On change collapse: There are no key');
    }

    let collapseKeys = helpers.getState()['collapseKeys'];
    let newKeys = [];

    collapseKeys = Array.isArray(collapseKeys) && collapseKeys.length ? [...collapseKeys] : [];

    if (collapseKeys.includes(payload)) {
      newKeys = [...collapseKeys].filter(item => item !== payload);
    } else {
      newKeys = [...collapseKeys, payload];
    }

    action.setCollapseKeys(newKeys);
  }),

  /**
   * Get field list
   */
  getFieldList: thunk(async (action, payload, helpers) => {
    const { visibleDetailRelation, fieldListData, ticketListData, isNotSetFieldRelationList } = payload;
    const processList = helpers.getStoreState()?.global?.globalProcessList;

    let newFieldList = handleGetBasicFieldList({ fieldListData, ticketListData });
    const newFieldRelationList = [];

    for (let index = 0; index < fieldListData[WORK_ITEM_TEST_RUN_ID].length; index++) {
      const field = fieldListData[WORK_ITEM_TEST_RUN_ID][index];

      if (field?.componentType === COMPONENT_TYPE.RELATION && visibleDetailRelation) {
        newFieldRelationList.push(field);

        handleGetFieldListForRelation({
          field,
          fieldListData,
          ticketListData,
          processList,
          helpers,
          callback: list => newFieldList.push(...list)
        });
      }
    }

    // Set relation field
    if (!isNotSetFieldRelationList) {
      action.setFieldRelationList(newFieldRelationList);
    }

    let list = [...newFieldList].filter(
      field => field && !field?.invisible && field?.refName !== 'latestResult.testRun'
    );

    if (visibleDetailRelation) {
      list = list.filter(field => field?.componentType !== COMPONENT_TYPE.RELATION);
    }

    list = removeDuplicate(list, 'refName');

    return list;
  }),

  /**
   * Get added testcases list
   */
  getAddedTestCasesList: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingList(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;

      const query = {
        path: {
          [OPERATION_VALUE_REGEX]: payload,
          $options: 'si'
        }
      };

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }${ENDPOINTS.TEST_PLAN_TEST_RUN}/testcases?${qs.stringify(query)}`;

      const res = await Http.get(url).then(res => res.data);

      action.setAddedTestCasesList(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  })
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Divider, Row } from 'antd';
import { ImportOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useStoreState } from 'easy-peasy';

import env from '../../env';
import { SafeInnerHtml } from '../../components';

export const ImportTipModal = ({ visible, className = '', onNext, onCancel, ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  // For global store
  const globalProject = useStoreState(state => state.global.globalProject);
  const globalTenant = useStoreState(state => state.global.globalTenant);

  return (
    <Modal
      open={visible}
      width={900}
      footer={null}
      forceRender
      centered // For "modal-fixed-header"
      wrapClassName="modal-fixed-header" // Enable "centered" mode, wrap content by class "modal-body-with-scroll"
      className={`c-import-tip-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${className}`}
      onCancel={onCancel}
      {...rest}
    >
      <div className="px-4 pt-3">
        <Row justify="space-between" align="middle">
          <h3 className="text-primary font-weight-medium font-size-16 m-0">
            <ImportOutlined rotate={180} /> {t('template.importQuickTips')}
          </h3>
        </Row>

        <Divider className="mt-3 mb-0" />
      </div>

      <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
        <div className="form-wrapper p-4">
          <div className="font-size-16 font-weight-medium mb-2">{t('template.quickTips')}</div>
          <div>
            <SafeInnerHtml
              html={t('template.importQuickTipsNote', {
                previewLink: `/${env.REACT_APP_PREFIX_PATH}${globalTenant?.tenantKey}/${globalProject?.projectKey}/_settings/import_export-template`
              })}
              options={{ ADD_ATTR: ['target'] }}
            />
          </div>
        </div>
      </div>

      <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
        <Divider className="mt-0 mb-3" />

        <div className="text-right">
          <Button type="text" onClick={onCancel}>
            {t('common.cancel')}
          </Button>

          <Button icon={<ArrowRightOutlined />} onClick={onNext}>
            {t('common.next')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

import { Modal, Form, Button, Select, Input, Spin, Divider, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { SaveOutlined, FullscreenOutlined, FullscreenExitOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TurndownService from 'turndown';
import { useStoreActions, useStoreState } from 'easy-peasy';

import {
  EXTERNAL_ID,
  JIRA_PLATFORM_ID,
  WORK_ITEM_DEFECT_ID,
  TESTMAN_PLATFORM_ID,
  ROLE_APP_ACCESS_MANAGER,
  INTEGRATION_DEFECT_SYSTEM,
  PRODUCT_KEY_ON_URL_MANAGER,
  ENDPOINTS
} from '../../constants';
import WorkTicketTypeList from './work-ticket-type-list';
import JiraIssueTypeList from './jira-issue-type-list';
import ExistItem from './exist-item';
import ExternalItem from './external-item';

const DEFECT_TYPE_CREATE = 1;
const DEFECT_TYPE_EXIST = 2;
const EXTERNAL_DEFECT = 3;
const OPTION_CREATE_DEFECT = [
  {
    key: DEFECT_TYPE_CREATE,
    title: 'defect.newDefect'
  },
  {
    key: DEFECT_TYPE_EXIST,
    title: 'defect.existingDefect'
  },
  {
    key: EXTERNAL_DEFECT,
    title: 'defect.externalDefect'
  }
];

const LANG_MESSAGE_REQUIRED = 'message.required';

const CreateDefectModal1 = ({
  visible,
  className,
  onCancel,
  rest,
  onNext,
  setDefect,
  defect,
  handleCreateRelation,
  loading = false,
  title = '',
  testStep = []
}) => {
  const [t] = useTranslation('akaat');
  const [form] = Form.useForm();

  // For global store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);
  const globalProject = useStoreState(state => state.global.globalProject);
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalUserTenant = useStoreState(state => state.global.globalUserTenant);

  // For work ticket
  const loadingWorkTicketItem = useStoreState(state => state.managerWorkItem.loadingWorkTicketItem);

  // For integration store
  const getIntegrationItem = useStoreActions(action => action.integration.getIntegrationItem);
  const integrationItem = useStoreState(state => state.integration.integrationItem);

  // For jira store
  const updateIssue = useStoreActions(action => action.jiraIntegration.updateIssue);
  const loadingJiraItem = useStoreState(state => state.jiraIntegration.loadingItem);

  // For component state
  const [typeCreateDefect, setTypeCreateDefect] = useState(DEFECT_TYPE_EXIST);
  const [defectSystemList, setDefectSystemList] = useState([]);
  const [jiraIssueTypeId, setJiraIssueTypeId] = useState();
  const [fullScreen, setFullScreen] = useState(false);
  const [visibleFullScreenTooltip, setVisibleFullScreenTooltip] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      defectSystem: defect?.defectSystem || undefined,
      workItemType: defect?.workItemType || undefined,
      jiraIssueType: defect?.jiraIssueType || undefined
    });
  }, [form, defect]);

  /**
   * Get integration system
   */
  useEffect(() => {
    if (!globalProject?.projectKey || !globalTenant.tenantKey) {
      return;
    }

    getIntegrationItem({ projectKey: globalProject.projectKey, tenantKey: globalTenant.tenantKey });

    // eslint-disable-next-line
  }, [globalProject, globalTenant]);

  /**
   * Set data defect system list
   */
  useEffect(() => {
    const list = [];

    if (Array.isArray(globalUserInfo?.permissions) && globalUserInfo.permissions.includes(ROLE_APP_ACCESS_MANAGER)) {
      list.push(INTEGRATION_DEFECT_SYSTEM[PRODUCT_KEY_ON_URL_MANAGER]);

      if (!defect.defectSystem) {
        setDefect({ ...defect, defectSystem: PRODUCT_KEY_ON_URL_MANAGER });
      }
    }

    if (integrationItem) {
      Object.keys(integrationItem).forEach(value => {
        switch (value) {
          case JIRA_PLATFORM_ID: {
            const defectMapping = integrationItem?.jira?.workTicketMapping?.find(
              value => value.workTicketID === WORK_ITEM_DEFECT_ID
            );
            if (defectMapping) {
              list.push(INTEGRATION_DEFECT_SYSTEM[value]);
              setJiraIssueTypeId(defectMapping?.issueID);
              if (!defect.defectSystem) {
                setDefect({ ...defect, defectSystem: value });
              }
            }

            break;
          }

          default: {
          }
        }
      });
    }
    setDefectSystemList(list);

    // eslint-disable-next-line
  }, [integrationItem, globalUserTenant, globalUserInfo]);

  /**
   * Handle submit
   */
  const hanldeSubmit = async values => {
    if (!values || !globalProject.projectKey || !globalTenant?.tenantKey) {
      return;
    }

    if (typeCreateDefect === DEFECT_TYPE_CREATE) {
      onNext(values);
    }

    if (typeCreateDefect === EXTERNAL_DEFECT) {
      const defectLink = {
        name: values?.item?.text || values?.item?.url,
        type: EXTERNAL_ID,
        link: values?.item?.url
      };

      handleCreateRelation(defectLink);
    }

    if (typeCreateDefect === DEFECT_TYPE_EXIST) {
      switch (values?.defectSystem) {
        case JIRA_PLATFORM_ID: {
          const rawHtml = testStep;
          const turndownService = new TurndownService();
          const markdown = turndownService.turndown(rawHtml.join(''));

          const remoteLink = {
            title: `${window.location.origin}${window.location.pathname}`,
            link: window.location.href
          };

          const formData = {
            description: markdown !== '' ? markdown : title
          };
          const res = await updateIssue({
            id: values.item.id,
            projectKey: globalProject.projectKey,
            tenantKey: globalTenant.tenantKey,
            remoteLink,
            formData
          });

          if (res) {
            const defectLink = {
              id: values.item.id,
              name: values.item.key,
              type: JIRA_PLATFORM_ID,
              link: `${defect?.jiraIssueType?.self?.split(ENDPOINTS.JIRA_REST_API_ISSUE_LINK_TYPES)[0]}/browse/${
                values.item.key
              }`
            };

            handleCreateRelation(defectLink);
          }
          break;
        }
        case TESTMAN_PLATFORM_ID: {
          handleCreateRelation(values.item);
          break;
        }
      }
    }
  };

  return (
    <>
      <Modal
        open={visible}
        maskClosable={false}
        width={700}
        footer={null}
        centered // For "modal-fixed-header"
        wrapClassName="modal-fixed-header"
        forceRender // For fix: Instance created by `useForm` is not connected to any Form element.
        className={`c-create-defect-modal-1 for-basic-defect hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${
          fullScreen ? 'full-screen-modal' : ''
        } ${className}`}
        onCancel={onCancel}
        {...rest}
      >
        <Form form={form} layout="vertical" onFinish={hanldeSubmit}>
          <div className="px-4 pt-3">
            <Row justify="space-between" align="middle">
              <h3 className="text-primary font-weight-medium font-size-16 m-0">{t('defect.createDefect')}</h3>

              <Row align="middle" justify="end" wrap={false} className="box-extra">
                <Tooltip
                  open={visibleFullScreenTooltip}
                  title={fullScreen ? t('common.exitFullScreen') : t('common.fullScreen')}
                  placement={fullScreen ? 'bottomRight' : 'bottom'}
                  destroyTooltipOnHide={true}
                  overlayStyle={{ pointerEvents: 'none' }}
                  onOpenChange={setVisibleFullScreenTooltip}
                >
                  <Button
                    icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                    type="link"
                    className="text-gray-2 text-hover-primary border-0 bg-transparent w-auto h-auto p-0 ml-3"
                    onClick={() => {
                      setFullScreen(!fullScreen);
                      setVisibleFullScreenTooltip(false);
                    }}
                  />
                </Tooltip>
              </Row>
            </Row>

            <Divider className="mt-3 mb-0" />
          </div>

          <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
            <div className="form-wrapper p-4">
              <Spin
                indicator={<Loading3QuartersOutlined spin />}
                spinning={loading || loadingJiraItem || loadingWorkTicketItem}
              >
                <Form.Item
                  label={t('defect.defectSystem')}
                  name="defectSystem"
                  rules={[{ required: true, message: t(LANG_MESSAGE_REQUIRED) }]}
                >
                  <Select placeholder={t('common.pleaseSelect')} onChange={val => setDefect({ defectSystem: val })}>
                    {Array.isArray(defectSystemList) &&
                      defectSystemList.length > 0 &&
                      defectSystemList.map(platform => {
                        return (
                          <Select.Option value={platform.id} key={platform.id} title={platform.title}>
                            {platform.icon}&nbsp;{platform.title}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                {defect.defectSystem === TESTMAN_PLATFORM_ID && <WorkTicketTypeList />}
                {defect.defectSystem === JIRA_PLATFORM_ID && (
                  <JiraIssueTypeList setDefect={setDefect} jiraIssueTypeId={jiraIssueTypeId} form={form} />
                )}

                <Form.Item
                  className="create-defect-type"
                  name="item"
                  rules={[{ required: true, message: t(LANG_MESSAGE_REQUIRED) }]}
                  label={
                    <Select
                      dropdownMatchSelectWidth={false}
                      defaultValue={DEFECT_TYPE_EXIST}
                      onChange={val => {
                        setTypeCreateDefect(val);
                        if (val === EXTERNAL_DEFECT) {
                          form.setFieldsValue({ item: EXTERNAL_DEFECT });
                        } else {
                          form.setFieldsValue({ item: '' });
                        }
                      }}
                    >
                      {Array.isArray(OPTION_CREATE_DEFECT) &&
                        OPTION_CREATE_DEFECT.length > 0 &&
                        OPTION_CREATE_DEFECT.map(type => {
                          return (
                            <Select.Option value={type.key} key={type.key} title={t(type.title)}>
                              {t(type.title)}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  }
                >
                  {typeCreateDefect === DEFECT_TYPE_CREATE && (
                    <Input
                      placeholder={t('defect.enterDefectName')}
                      onChange={e => {
                        setDefect({ item: e?.target?.value });
                        form.setFieldsValue({ item: e?.target?.value });
                      }}
                    />
                  )}

                  {typeCreateDefect === DEFECT_TYPE_EXIST && <ExistItem defect={defect} form={form} />}
                  {typeCreateDefect === EXTERNAL_DEFECT && <ExternalItem defect={defect} form={form} />}
                </Form.Item>
              </Spin>
            </div>
          </div>

          <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
            <Divider className="mt-0 mb-3" />

            <div className="text-right">
              <Button type="text" onClick={onCancel}>
                {t('common.cancel')}
              </Button>

              {typeCreateDefect === DEFECT_TYPE_CREATE && (
                <Button className="ml-2" type="primary" htmlType="submit" icon={<SaveOutlined />}>
                  {t('common.next')}
                </Button>
              )}

              {(typeCreateDefect === DEFECT_TYPE_EXIST || typeCreateDefect === EXTERNAL_DEFECT) && (
                <Button className="ml-2" type="primary" htmlType="submit" icon={<SaveOutlined />}>
                  {t('common.save')}
                </Button>
              )}
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CreateDefectModal1;

import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../core';

export const model = {
  /**
   * State
   */
  loadingPreviewTestCaseDataImport: false,

  /**
   * Action: Set loading preview test case data import
   */
  setLoadingPreviewTestCaseDataImport: action((state, payload) => {
    if (state?.loadingPreviewTestCaseDataImport === undefined) {
      return;
    }

    state.loadingPreviewTestCaseDataImport = payload;
  }),

  /**
   * Action: Call api to get preview test case data import
   */
  getPreviewTestCaseDataImport: thunk(async (action, payload, helpers) => {
    try {
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;

      if (!globalProject?.projectKey || !globalTenant?.tenantKey) {
        throw new Error('Get preview test case data import: Invalid Params');
      }

      action.setLoadingPreviewTestCaseDataImport(true);

      const url = `${ENDPOINTS.BIG_DATA}${ENDPOINTS.MANAGER_COMMON}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.IMPORT}/preview-test-case`;
      const res = await Http.post(url, payload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingPreviewTestCaseDataImport(false);
    }
  }),

  /**
   * Action: Call api to import test case
   */
  importTestCase: thunk(async (action, payload, helpers) => {
    try {
      const newPayload = { ...payload };
      const progressBar = payload?.progressBar;
      delete newPayload.progressBar;

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const setImportInfo = helpers.getStoreActions()?.global?.setImportInfo;

      if (!globalProject?.projectKey || !globalTenant?.tenantKey) {
        throw new Error('Import test case: Invalid Params');
      }

      const progressBarValue = {
        title: progressBar?.title,
        workTicketIcon: progressBar?.workTicketIcon,
        fileName: newPayload?.fileName
      };

      setImportInfo({
        key: progressBar?.key,
        info: { ...progressBarValue, percent: 0 }
      });

      helpers.getStoreActions()?.global?.setIsImporting(true);

      const url = `${ENDPOINTS.BIG_DATA}${ENDPOINTS.MANAGER_COMMON}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.IMPORT}/import-test-case`;
      const res = await Http.post(url, newPayload).then(res => res.data);

      setImportInfo({
        key: progressBar?.key,
        info: { ...progressBarValue, percent: 100 }
      });

      notification.success({
        message: i18next.t('akaat:testRepo.messageImportingTestCase'),
        description: i18next.t('akaat:message.importedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      helpers.getStoreActions()?.global?.setIsImporting(false);
    }
  })
};

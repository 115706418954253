import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { read as readXLSX } from 'xlsx';
import { Spin, Form, Input, Modal, Button, Select, Row, Divider, Col } from 'antd';
import {
  PlusOutlined,
  ImportOutlined,
  ReloadOutlined,
  ArrowLeftOutlined,
  FolderViewOutlined,
  Loading3QuartersOutlined
} from '@ant-design/icons';

import env from '../../env';
import { DEFAULT_ORDER, SEPARATE_TEST_STEP_RULE } from '../../constants';
import { useFile } from '../../common/hooks';
import { AttributeList } from '../../components';
import { UploadExcel } from '../../components/basic-upload-for-import-file/upload-excel';

const ACCEPT_FILE_TYPES = ['xlsx', 'xls', 'csv'];
const LANG_MESSAGE_REQUIRED = 'message.required';
const LANG_PLEASE_SELECT = 'common.pleaseSelect';

export const ImportFileModal = ({
  visible,
  uploadPath,
  workTicketId,
  loading,
  className = '',
  restUploadExcel,
  onGetPreviewData,
  onBack,
  onCancel,
  ...rest
}) => {
  const previewBtn = useRef(null);
  const [form] = Form.useForm();

  // For language
  const [t] = useTranslation('akaat');

  // For upload
  const { uploadFileImport } = useFile();

  // For global store
  const globalProject = useStoreState(state => state.global.globalProject);
  const globalTenant = useStoreState(state => state.global.globalTenant);

  // For import template store
  const getTemplateList = useStoreActions(action => action.importTemplate.getTemplateList);
  const setTemplateList = useStoreActions(action => action.importTemplate.setTemplateList);
  const templateListData = useStoreState(state => state.importTemplate.data);
  const loadingListTemplateList = useStoreState(state => state.importTemplate.loadingList);

  // Component state
  const [templateId, setTemplateId] = useState(null);
  const [fileInfo, setFileInfo] = useState({});
  const [sheetList, setSheetList] = useState([]);
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);

  /**
   * On get template list
   */
  const onGetTemplateList = () => {
    getTemplateList({
      page: null,
      limit: null,
      order: DEFAULT_ORDER,
      filter: {
        workTicket: workTicketId
      }
    });
  };
  /**
   * Get template list
   */
  useEffect(() => {
    if (!workTicketId) {
      return;
    }

    onGetTemplateList();
  }, [workTicketId]);

  /**
   * Handle before upload
   */
  const handleBeforeUpload = async file => {
    if (!file) {
      return;
    }

    const excelFileData = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      if (rABS) {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsArrayBuffer(file);
      }

      reader.onloadend = e => {
        const bstr = e?.target?.result;
        const excelFile = readXLSX(bstr, {
          sheetRows: 0,
          type: 'binary',
          bookVBA: true
        });

        resolve(excelFile);
      };

      reader.onerror = error => {
        reject(error);
      };
    });

    setSheetList(excelFileData?.SheetNames);
  };

  /**
   * On submit and search
   */
  const onSubmit = async values => {
    try {
      if (!values || !(Array.isArray(templateListData) && templateListData.length)) {
        return;
      }

      if (values?.endRow && +values?.endRow < +values?.startRow) {
        form.setFields([{ name: 'endRow', errors: [t('template.leaveBlankOrGreaterThanStartingRow')] }]);
        return;
      }

      setLoadingUploadFile(true);

      const template = templateListData.find(item => item?._id === values?.templateId);

      const uploadedFile = await uploadFileImport({
        file: fileInfo?.file,
        uploadPath
      });

      if (!uploadedFile?.downloadUrl) {
        return;
      }

      const newParams = {
        template,
        file: fileInfo?.file,
        fileName: fileInfo?.name,
        fileUrl: uploadedFile?.downloadUrl,
        filePath: uploadedFile?.filePath,
        sheetName: values?.sheetName,
        startRow: values?.startRow,
        endRow: values?.endRow || 0,
        startRow: values?.startRow
      };

      if (template?.fieldMapping?.separateTestStepType) {
        const separateTestStep = SEPARATE_TEST_STEP_RULE.find(
          r => r?.key === template?.fieldMapping?.separateTestStepType
        );

        newParams.separateTestStep = separateTestStep;
      }

      onGetPreviewData(newParams);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUploadFile(false);
    }
  };

  /**
   * Unmount
   */
  useEffect(() => {
    return () => {
      setTemplateList(null);
    };
  }, [setTemplateList]);

  return (
    <>
      <Modal
        open={visible}
        maskClosable={false}
        keyboard={false}
        width={800}
        zIndex={1004}
        footer={null}
        forceRender
        centered // For "modal-fixed-header"
        wrapClassName="modal-fixed-header" // Enable "centered" mode, wrap content by class "modal-body-with-scroll"
        onCancel={onCancel}
        className={`c-import-file-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${className}`}
        {...rest}
      >
        <Form form={form} labelCol={{ sm: 6 }} wrapperCol={{ sm: 18 }} labelAlign="left" onFinish={onSubmit}>
          <div className="px-4 pt-3">
            <Row justify="space-between" align="middle">
              <h3 className="text-primary font-weight-medium font-size-16 m-0">
                <ImportOutlined rotate={180} /> {t('template.import')}
              </h3>
            </Row>

            <Divider className="mt-3 mb-0" />
          </div>

          <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
            <div className="form-wrapper p-4">
              <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loading || loadingUploadFile}>
                <div className="font-weight-medium font-size-16">{t('template.selectTemplateAndDataToImport')}</div>
                <div className="text-italic text-gray pl-3 mb-3">{t('template.acceptFileImportNote')}</div>

                <Form.Item label={t('template.template')}>
                  <Row>
                    <Col style={{ flex: '0 0 calc(100% - 26px)' }}>
                      <Form.Item
                        name="templateId"
                        rules={[{ required: true, message: t(LANG_MESSAGE_REQUIRED) }]}
                        className="mb-0"
                      >
                        <Select
                          options={
                            Array.isArray(templateListData) && templateListData.length
                              ? templateListData.map(item => ({
                                  label: item?.name,
                                  value: item?._id
                                }))
                              : []
                          }
                          optionFilterProp="label"
                          showSearch
                          placeholder={t(LANG_PLEASE_SELECT)}
                          loading={loadingListTemplateList}
                          dropdownRender={node => (
                            <>
                              {node}
                              <Divider style={{ margin: '8px 0' }} />
                              <Button
                                href={`/${env.REACT_APP_PREFIX_PATH}${globalTenant?.tenantKey}/${globalProject?.projectKey}/_settings/import_export-template`}
                                type="link"
                                target="_blank"
                                rel="noreferrer"
                                icon={<PlusOutlined />}
                                className="border-0"
                              >
                                {t('template.createNewTemplate')}
                              </Button>
                            </>
                          )}
                          onSelect={setTemplateId}
                        />
                      </Form.Item>
                    </Col>

                    <Col style={{ flex: '0 0 26px', paddingTop: 5 }}>
                      <Button
                        type="link"
                        icon={<ReloadOutlined />}
                        className="w-auto h-auto border-0 text-gray-2 p-0 ml-2"
                        onClick={onGetTemplateList}
                      />
                    </Col>
                  </Row>
                </Form.Item>

                <div className="ant-row ant-row-middle ant-form-item">
                  <div className="ant-col ant-form-item-label ant-col-sm-6 text-left">
                    <label
                      htmlFor="sheetName"
                      className="ant-form-item-required"
                      title={t('template.selectFileForImport')}
                    >
                      {t('template.selectFileForImport')}
                    </label>
                  </div>

                  <div className="ant-col ant-form-item-control ant-col-sm-18">
                    <UploadExcel
                      acceptFileTypes={ACCEPT_FILE_TYPES}
                      setFileInfo={val => {
                        form.setFieldsValue({ sheetName: null });
                        form.setFieldsValue({ filePath: val?.name });
                        setFileInfo(val);
                      }}
                      fileInfo={fileInfo}
                      onBeforeUpload={handleBeforeUpload}
                      disabled={!templateId}
                      onRemoveFile={() => {
                        form.setFieldsValue({ filePath: null, sheetName: null });
                        form.validateFields(['filePath']);
                      }}
                      {...restUploadExcel}
                    />

                    <Form.Item
                      name="filePath"
                      rules={[{ required: true, whitespace: true, message: t(LANG_MESSAGE_REQUIRED) }]}
                      wrapperCol={{ span: 24 }}
                      className="is-hide-control-input mb-0"
                    >
                      <Input hidden />
                    </Form.Item>
                  </div>
                </div>

                <Form.Item
                  label={t('template.sheetName')}
                  name="sheetName"
                  rules={[{ required: true, whitespace: true, message: t(LANG_MESSAGE_REQUIRED) }]}
                >
                  <Select
                    options={
                      Array.isArray(sheetList) && sheetList.length
                        ? sheetList.map(item => ({
                            label: item,
                            value: item
                          }))
                        : []
                    }
                    optionFilterProp="label"
                    showSearch
                    placeholder={t(LANG_PLEASE_SELECT)}
                    disabled={!fileInfo?.file}
                  />
                </Form.Item>

                <Form.Item
                  label={t('template.startingRowNumber')}
                  name="startRow"
                  rules={[
                    { required: true, message: t(LANG_MESSAGE_REQUIRED) },
                    { pattern: /^[0-9]*$/, message: t('message.fieldMustBeAnInteger') }
                  ]}
                >
                  <Input placeholder={t('template.startingRowNumberPlaceholder')} className="w-100" />
                </Form.Item>

                <Form.Item
                  label={t('template.endingRowNumber')}
                  name="endRow"
                  rules={[{ required: false }, { pattern: /^[0-9]*$/, message: t('message.fieldMustBeAnInteger') }]}
                >
                  <Input placeholder={t('template.endingRowNumberPlaceholder')} className="w-100" />
                </Form.Item>

                <div className="mt-2 d-none">
                  <AttributeList
                    fromModule="IMPORT_FILE_MODAL"
                    workTicketId={workTicketId}
                    form={form}
                    isReadOnly={false}
                    leftColumnProp={{ xs: 8, sm: 6 }}
                    rightColumnProp={{ xs: 16, sm: 18 }}
                  />
                </div>
              </Spin>
            </div>
          </div>

          <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
            <Divider className="mt-0 mb-3" />

            <div className="text-right">
              <Button type="text" onClick={onCancel}>
                {t('common.cancel')}
              </Button>

              <Button icon={<ArrowLeftOutlined />} onClick={onBack}>
                {t('common.previous')}
              </Button>

              <Button
                ref={previewBtn}
                disabled={loading || loadingUploadFile}
                htmlType="submit"
                type="primary"
                icon={<FolderViewOutlined />}
              >
                {t('common.preview')}
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

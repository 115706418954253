import React, { useEffect, useState } from 'react';
import { Button, Progress, Spin } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { UpOutlined, CloseOutlined, ImportOutlined, DownOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { checkIsNotEmptyObject } from '../../common/utils';

import './style.scss';

export const ImportProgress = () => {
  // For language
  const [t] = useTranslation('akaat');

  // For global store
  const setImportInfo = useStoreActions(action => action.global.setImportInfo);
  const importInfo = useStoreState(state => state.global.importInfo);
  const setImportPromiseQueue = useStoreActions(action => action.global.setImportPromiseQueue);
  const setIsImporting = useStoreActions(action => action.global.setIsImporting);

  // Component state
  const [animatedCollapsed, setAnimatedCollapsed] = useState(false);

  /**
   * Unmount
   */
  useEffect(() => {
    return () => {
      setImportInfo(null);
      setImportPromiseQueue(null);
      setIsImporting(false);
    };
  }, [setImportInfo, setImportPromiseQueue, setIsImporting]);

  /**
   * Return html
   */
  if (!checkIsNotEmptyObject(importInfo)) {
    return;
  }

  return (
    <div className={`c-import-progress ${animatedCollapsed ? 'animated' : ''}`}>
      <div
        className="box-header text-truncate"
        onClick={() => {
          animatedCollapsed && setAnimatedCollapsed(false);
        }}
      >
        <div className="title-header text-truncate">
          <ImportOutlined rotate={180} /> {t('common.import')}
        </div>

        <div className="box-action">
          <Button
            title={animatedCollapsed ? t('common.expand') : t('common.collapse')}
            shape="circle"
            icon={animatedCollapsed ? <UpOutlined /> : <DownOutlined />}
            size="small"
            className="ml-1"
            onClick={() => setAnimatedCollapsed(pre => !pre)}
          />

          <Button
            title={t('common.close')}
            shape="circle"
            icon={<CloseOutlined />}
            size="small"
            className="ml-1"
            onClick={() => {
              setImportInfo(null);
              setImportPromiseQueue(null);
              setIsImporting(false);
            }}
          />
        </div>
      </div>

      <div className="box-body">
        <ul>
          {Object.keys(importInfo).map((k, index) => {
            return (
              <li key={index} className="text-truncate">
                {importInfo?.[k]?.workTicketIcon && (
                  <div className="col-icon">
                    <div className="work-ticket-icon-wrapper">
                      <Spin indicator={<Loading3QuartersOutlined spin />} spinning={importInfo?.[k]?.percent < 100}>
                        <span className="work-ticket-icon">{importInfo?.[k]?.workTicketIcon}</span>
                      </Spin>
                    </div>
                  </div>
                )}

                <div className="col-detail text-truncate">
                  <div title={importInfo?.[k]?.title} className="txt-title text-truncate">
                    {importInfo?.[k]?.title}
                  </div>
                  <div title={importInfo?.[k]?.fileName} className="txt-file-name text-truncate">
                    {importInfo?.[k]?.fileName}
                  </div>

                  <div className="box-progress">
                    <Progress percent={importInfo?.[k]?.percent >= 100 ? 100 : importInfo?.[k]?.percent} />
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';
import moment from 'moment';
import slug from 'slug';

import { ENDPOINTS, JIRA_DATA_TYPE_ISSUELINK_TYPES_ID } from '../../../../constants';
import { Http, handleError, handleJiraThrowError } from '../../../../core';
import { buildQuery, mappingJiraUser, reactSessionStorage } from '../../../../common/utils';

export const model = {
  /**
   * State
   */
  data: [],
  total: 0,
  createIssueMeta: [],
  fieldListCreateIssueMeta: [],
  assignableUserList: [],
  workflowStatusList: [],
  workflowTransitionList: [],
  priorityList: [],
  editingConnection: {},
  suggestionList: {},
  query: {},
  jiraAuthUrlToken: {},
  loadingList: false,
  loadingItem: false,
  loadingIssueMeta: false,
  loadingWorkflowStatusList: false,
  loadingWorkflowTransitionList: false,
  loadingPriorityList: false,
  loadingAssignableUserList: false,
  loadingFieldListCreateIssueMeta: false,

  /**
   * Action: Set connection list
   */
  setConnectionList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) {
      return;
    }

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    state.data = payload.rows;
    state.total = payload.count;
  }),

  /**
   * Action: Set issue list
   */
  setIssueList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) {
      return;
    }

    if (!(Array.isArray(payload.issues) && payload.issues.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    state.data = payload.issues;
    state.total = payload.total;
  }),

  /**
   * Action: Set editing connection
   */
  setEditingConnection: action((state, payload) => {
    if (state?.editingConnection === undefined) {
      return;
    }

    state.editingConnection = payload;
  }),

  /**
   * Action: Set loading jira issue meta
   */
  setLoadingIssueMeta: action((state, payload) => {
    if (state?.loadingIssueMeta === undefined) {
      return;
    }

    state.loadingIssueMeta = payload;
  }),

  /**
   * Action: Set jira issue type meta data
   */
  setCreateIssueMeta: action((state, payload) => {
    if (!(state?.createIssueMeta !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) || payload?.length)) {
      state.createIssueMeta = [];
      return;
    }

    state.createIssueMeta = payload;
  }),
  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) {
      return;
    }

    state.query = payload;
  }),

  /**
   * Action: Set jira auth url token
   */
  setJiraAuthUrlToken: action((state, payload) => {
    if (state?.jiraAuthUrlToken === undefined) {
      return;
    }

    if (!payload?.token || !payload?.url) {
      state.jiraAuthUrlToken = {};
    }

    state.jiraAuthUrlToken = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) {
      return;
    }

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) {
      return;
    }

    state.loadingItem = payload;
  }),

  /**
   * Action: Set loading jira isue list
   */
  setLoadingAssignableUserList: action((state, payload) => {
    if (state?.loadingAssignableUserList === undefined) {
      return;
    }

    state.loadingAssignableUserList = payload;
  }),

  /**
   * Action: Set jira suggestion list
   */
  setSuggestionList: action((state, payload) => {
    if (payload === undefined) {
      state.suggestionList = {};
      return;
    }

    if (!payload.fieldId || !payload.data) {
      return;
    }

    let { fieldId, data } = payload;

    if (fieldId === JIRA_DATA_TYPE_ISSUELINK_TYPES_ID) {
      const issueLinkTypes = [];

      if (Array.isArray(data?.issueLinkTypes) && data?.issueLinkTypes?.length) {
        data?.issueLinkTypes?.forEach(item => {
          if (item.inward !== item.outward) {
            issueLinkTypes.push({
              id: `${item.id}_${slug(item.inward)}`,
              name: item.inward,
              typeName: item.name,
              outward: item.inward,
              type: 'inward'
            });

            issueLinkTypes.push({
              id: `${item.id}_${slug(item.outward)}`,
              name: item.outward,
              typeName: item.name,
              inward: item.inward,
              type: 'outward'
            });
          } else {
            issueLinkTypes.push({
              ...item,
              typeName: item.name,
              type: 'inward',
              id: `${item.id}_${slug(item.inward)}`
            });
          }
        });
      }

      data = issueLinkTypes;
    }

    state.suggestionList = {
      ...state.suggestionList,
      [fieldId]: data
    };
  }),

  /**
   * Action: Set loading jira issue
   */
  setLoadingIssueItem: action((state, payload) => {
    if (state?.loadingIssueItem === undefined) {
      return;
    }

    state.loadingIssueItem = payload;
  }),

  /**
   * Action: Set loading jira workflow status issue
   */
  setLoadingWorkflowStatusList: action((state, payload) => {
    if (state?.loadingWorkflowStatusList === undefined) {
      return;
    }

    state.loadingWorkflowStatusList = payload;
  }),

  /**
   * Action: Set loading jira transition list
   */
  setLoadingWorkflowTransitionList: action((state, payload) => {
    if (state?.loadingWorkflowTransitionList === undefined) {
      return;
    }

    state.loadingWorkflowTransitionList = payload;
  }),

  /**
   * Action: Set loading jira priority
   */
  setLoadingPriorityList: action((state, payload) => {
    if (state?.loadingPriorityList === undefined) {
      return;
    }

    state.loadingPriorityList = payload;
  }),

  /**
   * Action: Set loading get field list create issue meta
   */
  setLoadingFieldListCreateIssueMeta: action((state, payload) => {
    if (state?.loadingFieldListCreateIssueMeta === undefined) {
      return;
    }

    state.loadingFieldListCreateIssueMeta = payload;
  }),

  /**
   * Action: Set field list create issue meta
   */
  setFieldListCreateIssueMeta: action((state, payload) => {
    if (!(state?.fieldListCreateIssueMeta !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) || payload.length)) {
      state.fieldListCreateIssueMeta = [];
      return;
    }

    const fieldList = payload.filter(
      field => field?.fieldId !== 'parent' && field?.fieldId !== 'customfield_10019' && field.fieldId !== 'attachment'
    );

    state.fieldListCreateIssueMeta = fieldList;
  }),

  /**
   * Action: Set jira user assignable list
   */
  setAssignableUserList: action((state, payload) => {
    if (!(state?.assignableUserList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) || !payload.length)) {
      state.assignableUserList = [];
      return;
    }

    state.assignableUserList = payload.map(user => ({
      ...mappingJiraUser(user)
    }));
  }),

  /**
   * Action: Set jira workflow status list
   */
  setWorkflowTransitionList: action((state, payload) => {
    if (!(state?.workflowTransitionList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) || !payload.length)) {
      state.workflowTransitionList = [];
      return;
    }

    state.workflowTransitionList = payload;
  }),

  /**
   * Action: Set jira priority list
   */
  setPriorityList: action((state, payload) => {
    if (!(state?.priorityList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) || !payload.length)) {
      state.priorityList = [];
      return;
    }

    state.priorityList = payload.map(priority => ({
      value: priority?.id,
      label: priority?.name,
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <img className="mr-1" src={priority?.iconUrl} width={16} alt="Priority Icon" />
    }));
  }),

  /**
   * Action: Set jira workflow status list
   */
  setWorkflowStatusList: action((state, payload) => {
    if (!(state?.workflowStatusList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) || !payload.length)) {
      state.workflowStatusList = [];
      return;
    }

    state.workflowStatusList = payload;
  }),

  /**
   * Action: Call api to get connection list
   */
  getConnectionList: thunk(async (action, payload) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get connection list: Invalid Payload');
      }

      action.setLoadingList(true);
      action.setQuery(payload);

      const url = `${ENDPOINTS.INTEGRATION}?q=${buildQuery(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setConnectionList(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get connection by Id
   */
  getConnectionById: thunk(async (action, payload) => {
    try {
      if (!payload) {
        throw new Error('Get connection by id: Invalid Payload');
      }

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.INTEGRATION}/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingConnection(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to create connection
   */
  createConnection: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Create connection: Invalid Params');
      }

      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Create connection: Invalid Payload');
      }

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.JIRA}`;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:integration.messageCreatingConnection'),
        description: i18next.t('akaat:message.createdSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update connection
   */
  updateConnection: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Update connection: Invalid Params');
      }

      if (!payload?.id) {
        throw new Error('Update connection: Invalid Payload');
      }

      action.setLoadingItem(true);

      const { id, formData } = payload;

      const urlIntegration = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.JIRA}/${id}`;
      const urlSyncDataJira2TestMan = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.JIRA}/${ENDPOINTS.SYNC_JIRA_ONE_PROJECT}`;
      const restIntegration = await Http.put(urlIntegration, formData).then(res => res.data);

      //Auto sync data when link jira item with TestMan
      Http.get(urlSyncDataJira2TestMan);

      notification.success({
        message: i18next.t('akaat:integration.messageUpdatingConnection'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      return restIntegration;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete connection
   */
  deleteConnection: thunk(async (action, payload) => {
    try {
      if (!payload) {
        throw new Error('Delete connection: Invalid Payload');
      }

      // ==========> Delete connection
      const url = `${ENDPOINTS.INTEGRATION}/${payload}`;
      await Http.delete(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:integration.messageDeletingConnection'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to jira auth url
   */
  jiraAuthUrl: thunk(async (action, payload) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Jira auth url: Invalid Payload');
      }

      action.setLoadingItem(true);

      const url = ENDPOINTS.JIRA_AUTH_URL;
      const res = await Http.post(url, payload).then(res => res.data);

      handleJiraThrowError(res);

      action.setJiraAuthUrlToken(res);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to jira access token
   */
  jiraAccessToken: thunk(async (action, payload) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Jira access token: Invalid Payload');
      }

      action.setLoadingItem(true);

      const url = ENDPOINTS.JIRA_ACCESS_TOKEN;
      const res = await Http.post(url, payload).then(res => res.data);

      if (typeof res !== 'string' && res?.statusCode !== 200) {
        throw new Error(typeof res?.data === 'string' ? res.data : 'Error');
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api get jira issue type list
   */
  getCreateIssueMeta: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get create issue meta: Invalid Params');
      }
      action.setLoadingIssueMeta(true);

      // Ngăn không cho call api liên tục nhiều lần trong một khoảng thời gian ngắn (Đang để 0.25 giây).
      const timer = reactSessionStorage.getObject('getDataForJiraTimer', {});
      const currentTime = +timer?.getCreateIssueMeta;

      if (moment().clone().unix() <= currentTime) {
        return;
      }

      reactSessionStorage.setObject('getDataForJiraTimer', {
        ...timer,
        getCreateIssueMeta: moment().clone().add(0.25, 'second').unix()
      });

      const url = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}/jira/listissuetypes`;
      const res = await Http.get(url).then(res => res.data);

      action.setCreateIssueMeta(res?.data);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingIssueMeta(false);
    }
  }),

  /**
   * Action: Call api get jira issue type list
   */
  getFieldListCreateMeta: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get field list create meta: Invalid Params');
      }

      action.setLoadingFieldListCreateIssueMeta(true);

      const url = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}/jira/issue/createmeta?issuetype=${payload.issuetype}`;
      const res = await Http.get(url).then(res => res.data);

      action.setFieldListCreateIssueMeta(res?.data?.values);
      return res?.data?.values;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingFieldListCreateIssueMeta(false);
    }
  }),

  /**
   * Action: Call api get user assignable list
   */
  getAssignableUserList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (tenant) {
        return;
      } // To do

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get assignable user list: Invalid Params');
      }

      action.setLoadingAssignableUserList(true);

      // Ngăn không cho call api liên tục nhiều lần trong một khoảng thời gian ngắn (Đang để 0.25 giây).
      const timer = reactSessionStorage.getObject('getDataForJiraTimer', {});
      const currentTime = +timer?.assignableUserList;

      if (moment().clone().unix() <= currentTime) {
        return;
      }

      reactSessionStorage.setObject('getDataForJiraTimer', {
        ...timer,
        assignableUserList: moment().clone().add(0.25, 'second').unix()
      });

      const url = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}/jira/user/search?username=${payload?.username}`;
      const res = await Http.get(url).then(res => res.data);

      if (Array.isArray(res?.data) && res?.data.length) {
        const text = payload.username.toUpperCase();
        const result = res?.data?.filter(user => {
          return (
            (typeof user?.emailAddress === 'string' &&
              typeof user?.displayName === 'string' &&
              user?.emailAddress?.toUpperCase()?.indexOf(text) > -1) ||
            user?.displayName?.toUpperCase()?.indexOf(text) > -1
          );
        });
        action.setAssignableUserList(result);
        return result.map(user => ({
          ...mappingJiraUser(user)
        }));
      }

      return res?.data.map(user => ({
        ...mappingJiraUser(user)
      }));
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingAssignableUserList(false);
    }
  }),

  /**
   * Action: Call api get workflow transition
   */
  getWorkflowTransitionList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get workflow transition list: Invalid Params');
      }

      action.setLoadingWorkflowTransitionList(true);

      // Ngăn không cho call api liên tục nhiều lần trong một khoảng thời gian ngắn (Đang để 0.25 giây).
      const timer = reactSessionStorage.getObject('getDataForJiraTimer', {});
      const currentTime = +timer?.workflowTransitionList;

      if (moment().clone().unix() <= currentTime) {
        return;
      }

      reactSessionStorage.setObject('getDataForJiraTimer', {
        ...timer,
        workflowTransitionList: moment().clone().add(0.25, 'second').unix()
      });

      const url = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}/jira/issue/${payload}/transitions`;
      const res = await Http.get(url).then(res => res.data);

      if (Array.isArray(res?.data) && res?.data.length) {
        action.setWorkflowTransitionList(res?.data);
        return res?.data;
      }

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingWorkflowTransitionList(false);
    }
  }),

  /**
   * Action: Call api get priority list
   */
  getPriorityList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (tenant) {
        return;
      } // To do

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get priority list: Invalid Params');
      }

      action.setLoadingPriorityList(true);

      // Ngăn không cho call api liên tục nhiều lần trong một khoảng thời gian ngắn (Đang để 0.25 giây).
      const timer = reactSessionStorage.getObject('getDataForJiraTimer', {});
      const currentTime = +timer?.priorityList;

      if (moment().clone().unix() <= currentTime) {
        return;
      }

      reactSessionStorage.setObject('getDataForJiraTimer', {
        ...timer,
        priorityList: moment().clone().add(0.25, 'second').unix()
      });

      const url = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}/jira/priority`;
      const res = await Http.get(url).then(res => res.data);

      if (Array.isArray(res?.data) && res?.data.length) {
        action.setPriorityList(res?.data);
        return res?.data;
      }

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingPriorityList(false);
    }
  }),

  /**
   * Action: Call api get workflow status list
   */
  getWorkflowStatusList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (tenant) {
        return;
      } // To do

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get workflow status list: Invalid Params');
      }

      action.setLoadingWorkflowStatusList(true);

      // Ngăn không cho call api liên tục nhiều lần trong một khoảng thời gian ngắn (Đang để 0.25 giây).
      const timer = reactSessionStorage.getObject('getDataForJiraTimer', {});
      const currentTime = +timer?.workflowStatusList;

      if (moment().clone().unix() <= currentTime) {
        return;
      }

      reactSessionStorage.setObject('getDataForJiraTimer', {
        ...timer,
        workflowStatusList: moment().clone().add(0.25, 'second').unix()
      });

      const url = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}/jira/status`;
      const res = await Http.get(url).then(res => res.data);

      if (Array.isArray(res?.data) && res?.data.length) {
        const newData = res?.data.filter(item => item?.scope?.type === 'PROJECT');
        action.setWorkflowStatusList(newData);
        return newData;
      }

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingWorkflowStatusList(false);
    }
  }),

  /**
   * Action: Call api get user assignable list
   */
  getSuggestionList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get suggestion list: Invalid Params');
      }

      const { fieldId, autoCompleteUrl, value } = payload;

      const url = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}/jira/auto-complete`;
      const res = await Http.post(url, {
        autoCompleteUrl,
        value
      }).then(res => res.data);

      action.setSuggestionList({
        fieldId,
        data: res.data
      });

      return res.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingAssignableUserList(false);
    }
  }),

  /**
   * Action: Call api get user assignable list
   */
  createIssue: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Create issue: Invalid Params');
      }
      action.setLoadingItem(true);

      const { formData, remoteLink, update } = payload;

      const body = {
        update,
        fields: formData,
        remoteLink
      };
      const url = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}/jira/issue`;
      const res = await Http.post(url, body).then(res => res.data);

      return res.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api get user assignable list
   */
  updateIssue: thunk(async (action, payload) => {
    try {
      if (!payload?.projectKey || !payload?.tenantKey) {
        throw new Error('Update issue: Invalid Payload');
      }
      if (!payload?.id) {
        throw new Error('Update issue: No ID');
      }
      action.setLoadingItem(true);
      const { projectKey, tenantKey, formData, remoteLink, id } = payload;

      const body = {
        fields: formData || {},
        remoteLink
      };
      const url = `${ENDPOINTS.INTEGRATION}/${tenantKey}/${projectKey}/jira/issue/${id}`;
      await Http.put(url, body).then(res => res.data);

      return 1;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api get issue by jql
   */
  searchByJql: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Search by jql: Invalid Params');
      }

      if (!payload?.jql) {
        throw new Error('Search by jql: Invalid Payload');
      }

      action.setLoadingList(true);

      const { jql } = payload;
      const url = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}/jira/search-jql?jql=${jql}`;
      const res = await Http.get(url).then(res => res.data);

      if (res?.data) {
        action.setIssueList(res?.data);
      }
      return res.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api get issue by jql
   */
  deleteAttachment: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Delete attachment: Invalid Params');
      }

      const url = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}/jira/attachment?id=${payload}`;
      const res = await Http.delete(url).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
    }
  })
};

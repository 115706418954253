import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Upload, notification, Button, Tooltip } from 'antd';
import { UploadOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons';

import { formatBytes } from '../../common/utils';
import { useFile } from '../../common/hooks';
import { SafeInnerHtml } from '../safe-inner-html';

import './style.scss';

export const UploadExcel = ({
  fileInfo,
  setFileInfo,
  acceptFileTypes, // Extension of files, string[]
  maxFileSize,
  isReadOnly = false,
  onRemoveFile,
  onBeforeUpload,
  className = '',
  restDragger,
  disabled = false,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For upload
  const { checkValidType, checkValidSize } = useFile();

  // Component state
  const [loadingUpload, setLoadingUpload] = useState(false);

  /**
   * Before upload attachment
   */
  const beforeUploadAttachment = file => {
    if (!file) {
      return;
    }

    const isValidType = checkValidType({ file, acceptFileTypes });
    const isValidSize = checkValidSize({ file, maxFileSize });

    if (isValidType && isValidSize) {
      onBeforeUpload(file);
    }

    return isValidType && isValidSize;
  };

  /**
   * Handle change attachment
   */
  const handleChangeFile = info => {
    if (isReadOnly || !info?.file) {
      return;
    }

    if (!info?.file) {
      return;
    }

    if (info.file.status === 'uploading') {
      setLoadingUpload(true);
      return;
    }

    if (info.file.status === 'done') {
      setLoadingUpload(false);
      setFileInfo({ file: info.file.originFileObj, name: info.file.name, size: formatBytes(info.file.size) });
    } else if (info.file.status === 'error') {
      notification.error({
        message: t('common.upload'),
        description: t('message.fileHasFailedToUpload', { name: info.file.name })
      });
    } else {
    }
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  return (
    <Card bodyStyle={{ padding: '1rem' }} className={`c-basic-upload-for-import-file for-excel ${className}`} {...rest}>
      <Upload.Dragger
        accept={Array.isArray(acceptFileTypes) && acceptFileTypes.length ? acceptFileTypes.join(',') : ''}
        customRequest={dummyRequest}
        showUploadList={false}
        disabled={disabled || loadingUpload}
        className="w-100"
        beforeUpload={beforeUploadAttachment}
        onChange={handleChangeFile}
        {...restDragger}
      >
        <p className="ant-upload-drag-icon mb-0">
          {loadingUpload ? (
            <LoadingOutlined className="vertical-align-middle" style={{ fontSize: '1.3rem' }} />
          ) : (
            <UploadOutlined className="text-primary" style={{ verticalAlign: 'middle', fontSize: '1.3rem' }} />
          )}

          <SafeInnerHtml
            html={t('common.dropFileOrBrowser')}
            className="d-inline ml-2"
            style={{ opacity: loadingUpload ? 0.3 : null }}
          />
        </p>
      </Upload.Dragger>

      {fileInfo?.name && (
        <div className="file-info mt-2">
          <div className="file-name text-truncate">{fileInfo.name}</div>
          <div className="file-size">{fileInfo.size}</div>

          {!isReadOnly && (
            <Tooltip title={t('common.remove')} placement="top" destroyTooltipOnHide={true}>
              <Button
                type="link"
                danger
                icon={<DeleteOutlined />}
                size="small"
                className="btn-remove-file"
                onClick={() => {
                  setFileInfo({});
                  onRemoveFile();
                }}
              ></Button>
            </Tooltip>
          )}
        </div>
      )}
    </Card>
  );
};

import React from 'react';
import { Button, Col, Empty, Popconfirm, Row, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import { SYSTEM_FIELD_KEY } from '../../../constants';
import { getFieldName } from '../../../common/utils';
import { Drag2 } from '../../../assets/svg-icons';

import './style.scss';

const FieldList = ({ shownFields, isReadOnly, onReOrderField, onRemoveFieldToList }) => {
  // For language
  const [t, ] = useTranslation('akaat');

  /**
   * Drag handle
   */
  const DragHandle = SortableHandle(() => (
    <Tooltip
      title={t('common.dragDropToReorder')}
      placement="left"
      destroyTooltipOnHide={true}
      overlayStyle={{ pointerEvents: 'none' }}
    >
      <Drag2 className="btn-drag text-gray text-hover-primary font-size-14 cursor-move" />
    </Tooltip>
  ));

  /**
   * Sortable item
   */
  const SortableItem = SortableElement(props => {
    if (!props?.item) {
      return;
    }

    const { item, itemIndex } = props;
    const title = getFieldName(item);

    return (
      <div className="field-btn">
        {!isReadOnly && item?.refName !== SYSTEM_FIELD_KEY && <DragHandle />}

        <Row className="w-100 h-100">
          <Col span={22}>
            <h4 className="h3-field-name text-primary" style={{ paddingLeft: isReadOnly ? 30 : 70 }}>
              <span title={title} className="text-truncate">
                {title}
              </span>
            </h4>
          </Col>

          <Col span={2} style={{ alignSelf: 'center' }} className="text-center">
            {item?.refName !== SYSTEM_FIELD_KEY && (
              <Popconfirm
                placement="topRight"
                title={t('message.areYouSureRemove2')}
                okText={t('common.yes')}
                cancelText={t('common.no')}
                zIndex={1080}
                onConfirm={() => onRemoveFieldToList(itemIndex)}
              >
                <Tooltip
                  placement="top"
                  title={t('common.delete')}
                  destroyTooltipOnHide={true}
                  overlayStyle={{ pointerEvents: 'none' }}
                >
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    className="text-gray text-hover-danger font-size-14 bg-transparent border-0 p-0 m-0"
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </Col>
        </Row>
      </div>
    );
  });

  /**
   * Sortable list
   */
  const SortableList = SortableContainer(props => {
    return (
      <div className="field-btn-list">
        {props?.list.map((item, index) => (
          <SortableItem
            key={`${item?.orderId}`}
            index={index}
            itemIndex={index}
            item={item}
            disabled={item?.refName === SYSTEM_FIELD_KEY}
          />
        ))}
      </div>
    );
  });

  /**
   * On sort end
   */
  const onSortEnd = props => {
    if (props?.oldIndex === props?.newIndex) {
      return;
    }

    let newShownFields = Array.isArray(shownFields) && shownFields.length ? [...shownFields] : [];

    newShownFields = arrayMoveImmutable(newShownFields, props?.oldIndex, props?.newIndex);
    newShownFields = newShownFields.map((item, idx) => ({ ...item, orderId: idx + 1 }));

    onReOrderField(newShownFields);
  };

  return (
    <div className="c-config-grid-view-contents-field-list">
      {Array.isArray(shownFields) && shownFields.length > 0 ? (
        <SortableList
          list={shownFields.filter(field => field?.refName)}
          useDragHandle
          helperClass="row-dragging-field"
          onSortEnd={onSortEnd}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};

export default FieldList;

import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Result, Row, Divider, Badge, Alert, Tooltip, Select, Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { useStoreState } from 'easy-peasy';
import { arrayMoveImmutable } from 'array-move';
import {
  SaveOutlined,
  WarningOutlined,
  ArrowLeftOutlined,
  FolderViewOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';

import {
  PAGE_SIZE_10,
  FIELD_STEP,
  FIELD_TEST_DATA,
  COMPONENT_TYPE,
  WORK_ITEM_TESTCASE_ID,
  WORK_ITEM_TEST_RUN_ID,
  FIELD_EXPECTED_RESULT,
  FIELD_TEST_PRECONDITION,
  SYSTEM_FIELD_TEST_STEPS,
  SYSTEM_FIELD_EXTERNAL_KEY,
  CHANGE_TYPE_IMPORT_BOTH,
  CHANGE_TYPE_IMPORT_CREATE,
  CHANGE_TYPE_IMPORT_UPDATE,
  SYSTEM_FIELD_LATEST_RESULT,
  SYSTEM_FIELD_TEST_CASE_EXTERNAL_KEY
} from '../../constants';
import {
  sleep,
  getFieldName,
  scrollToSelector,
  checkIsNotEmptyArray,
  convertRawHtmlToPlainText
} from '../../common/utils';
import { BasicTable, EditableCell, SafeInnerHtml, StatusLabel } from '../../components';

const LANG_OK = 'common.ok';
const LANG_CANCEL = 'common.cancel';

export const ImportPreviewModal = ({
  visible,
  previewPayload,
  previewData,
  workTicketId,
  fieldList,
  loading = false,
  className = '',
  onGetPreviewData,
  onImport,
  onBack,
  onCancel,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For global store
  const ticketListData = useStoreState(state => state.global.ticketListData);

  // Component state
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [previewBody, setPreviewBody] = useState({ page: 1, limit: PAGE_SIZE_10, changeType: CHANGE_TYPE_IMPORT_BOTH });
  const [columnsPreview, setColumnsPreview] = useState([]);

  /**
   * Set data source
   */
  useEffect(() => {
    console.log('previewData', previewData);
    if (!checkIsNotEmptyArray(previewData?.rows)) {
      return;
    }

    let newData = previewData?.rows.map((item, index) => {
      const newItem = { ...item };

      return {
        ...newItem,
        index
      };
    });

    setDataSource(newData);
    setTotal(previewData?.count);
  }, [previewData]);

  /**
   * Compute preview field list
   */
  const previewFieldList = useMemo(() => {
    if (!checkIsNotEmptyArray(fieldList)) {
      return [];
    }

    let newFieldList = [...fieldList];

    if (workTicketId === WORK_ITEM_TEST_RUN_ID) {
      if (newFieldList.some(f => f?.refName === SYSTEM_FIELD_LATEST_RESULT)) {
        const oldIndex = newFieldList.findIndex(f => f.refName === SYSTEM_FIELD_LATEST_RESULT);
        newFieldList = arrayMoveImmutable(newFieldList, oldIndex, 0);
      }

      if (newFieldList.some(f => f?.refName === SYSTEM_FIELD_TEST_CASE_EXTERNAL_KEY)) {
        const oldIndex = newFieldList.findIndex(f => f.refName === SYSTEM_FIELD_TEST_CASE_EXTERNAL_KEY);
        newFieldList = arrayMoveImmutable(newFieldList, oldIndex, 0);
      }
    } else {
      if (newFieldList.some(f => f?.refName === SYSTEM_FIELD_EXTERNAL_KEY)) {
        const oldIndex = newFieldList.findIndex(f => f.refName === SYSTEM_FIELD_EXTERNAL_KEY);
        newFieldList = arrayMoveImmutable(newFieldList, oldIndex, 0);
      }
    }

    let newPreviewFieldList = [...newFieldList].flatMap(newField => {
      const oldField = {
        ...newField,
        refName: `old_${newField?.refName}`,
        originRefName: newField?.refName,
        isOldColumn: true
      };

      return [oldField, newField];
    });

    return newPreviewFieldList;
  }, [workTicketId, fieldList]);

  /**
   * Render special old cell value
   */
  const renderSpecialOldCellValue = ({ row, field }) => {
    let label = null;

    switch (field?.originRefName) {
      case SYSTEM_FIELD_TEST_CASE_EXTERNAL_KEY:
        label = row?.[`old_${SYSTEM_FIELD_TEST_CASE_EXTERNAL_KEY}`];
        break;

      case SYSTEM_FIELD_LATEST_RESULT:
        const status = row?.[`old_${SYSTEM_FIELD_LATEST_RESULT}`];
        label = <StatusLabel status={status} />;
        break;

      default:
        break;
    }

    return <div className={`${row?.[field?.originRefName]?.isUpdatedValue ? 'bg-old-version' : ''}`}>{label}</div>;
  };

  /**
   * Render old cell value
   */
  const renderOldCellValue = ({ row, field }) => {
    return (
      <div className={`${row?.[field?.originRefName]?.isUpdatedValue ? 'bg-old-version' : ''}`}>
        <EditableCell workTicketId={workTicketId} row={row} field={field} disableEditingCells={true} />
      </div>
    );
  };

  /**
   * Render new cell value
   */
  const renderNewCellValue = ({ val, field }) => {
    let label = null;

    switch (field?.componentType) {
      case COMPONENT_TYPE.HTML: {
        label = convertRawHtmlToPlainText(val?.value);
        break;
      }

      default: {
        label = val?.value;
        break;
      }
    }

    return <div className={`${val?.isUpdatedValue ? 'bg-new-version' : ''}`}>{label}</div>;
  };

  /**
   * Render error
   */
  const renderError = ({ val, title, field }) => {
    if (!val?.error || field?.refName === SYSTEM_FIELD_TEST_CASE_EXTERNAL_KEY) {
      return null;
    }

    let errorText = '';

    if (val?.error === 'message.required3') {
      errorText = t('message.required3', { name: title });
    } else if (
      val?.error === 'message.valueNotInTheSystem' &&
      [COMPONENT_TYPE.PICKLIST].includes(field?.componentType)
    ) {
      errorText = t('message.valueNotInTheSystem', { name: val?.valuesNotInSystem });
    } else if (val?.error === 'message.valueNotInTheSystem') {
      errorText = t('message.valueNotInTheSystem', { name: val?.value });
    } else if (val?.error) {
      errorText = t(val?.error);
    } else {
      errorText = t('common.error');
    }

    return <Alert type="error" message={errorText} style={{ minWidth: 200 }} className="text-center px-1 py-0" />;
  };

  /**
   * Riêng Test case external
   * Khi không tìm thấy Test case external trong db, thì sẽ hiển thị lỗi "External key doesn't exist." ở cột Old
   */
  const renderOldTestCaseExternalKeyError = ({ row, field, workTicketId }) => {
    if (
      workTicketId !== WORK_ITEM_TEST_RUN_ID ||
      !(
        field?.refName === SYSTEM_FIELD_TEST_CASE_EXTERNAL_KEY ||
        field?.originRefName === SYSTEM_FIELD_TEST_CASE_EXTERNAL_KEY
      )
    ) {
      return null;
    }

    if (!row?.[field?.originRefName]?.error) {
      return null;
    }

    let errorText = t(row?.[field?.originRefName]?.error);

    return <Alert type="error" message={errorText} style={{ minWidth: 200 }} className="text-center px-1 py-0" />;
  };

  /**
   * Set field list
   */
  useEffect(() => {
    if (!checkIsNotEmptyArray(previewFieldList)) {
      return;
    }

    const allColumns = previewFieldList.map(field => {
      const title = getFieldName(field, 'refName');
      const oldTitle = getFieldName(field, 'originRefName');

      const label = (
        <Badge
          count={field?.isOldColumn ? t('common.old') : t('common.new')}
          size="small"
          style={{ fontWeight: 500, color: '#262626', backgroundColor: field?.isOldColumn ? '#fcf1bc' : '#bfe2dd' }}
          className="ml-1"
        />
      );

      return {
        dataIndex: field?.refName,
        title: (
          <Row title={field?.isOldColumn ? oldTitle : title} align="middle" wrap={false}>
            {field?.isOldColumn ? oldTitle : title} {label}
          </Row>
        ),
        width: 150,
        render: (val, row) => {
          // Render old cell cho Test case external key và Latest result
          if (
            field?.isOldColumn &&
            [SYSTEM_FIELD_TEST_CASE_EXTERNAL_KEY, SYSTEM_FIELD_LATEST_RESULT].includes(field?.originRefName)
          ) {
            return (
              <>
                {renderSpecialOldCellValue({ row, field })}
                {renderOldTestCaseExternalKeyError({ row, field, workTicketId })}
              </>
            );
          }

          // Render old cell cho những field còn lại
          else if (field?.isOldColumn) {
            return renderOldCellValue({ row, field });
          }

          // Render new cell
          else {
            return (
              <>
                {renderNewCellValue({ val, field })}
                {renderError({ val, title, field })}
              </>
            );
          }
        }
      };
    });

    const validationStatusColumn = {
      dataIndex: 'validation',
      title: t('common.validation'),
      width: 116,
      className: 'text-center',
      render: (value, item) => (
        <Tooltip
          title={item?.isInvalidRow ? t('common.invalidItem') : t('common.validItem')}
          placement="top"
          destroyTooltipOnHide={true}
        >
          {item?.isInvalidRow ? (
            <WarningOutlined style={{ color: '#ff4d4f' }} className="font-size-14" />
          ) : (
            <CheckCircleOutlined style={{ color: '#28a745' }} className="font-size-14" />
          )}
        </Tooltip>
      )
    };

    const importStatusColumn = {
      dataIndex: 'importStatus',
      title: t('common.changeType'),
      width: 116,
      className: 'text-center',
      render: (value, item) => (
        <span className={item?.isUpdatedRow ? 'import-status-update' : 'import-status-create'}>
          {item?.isUpdatedRow ? t('common.update') : t('common.create')}
        </span>
      )
    };

    let newColumns = [];

    if (workTicketId === WORK_ITEM_TEST_RUN_ID) {
      newColumns = [validationStatusColumn, ...allColumns];
    } else {
      newColumns = [validationStatusColumn, importStatusColumn, ...allColumns];
    }

    setColumnsPreview(newColumns);
  }, [previewFieldList, workTicketId, ticketListData]);

  /**
   * Expanded row render
   */
  const expandedRowRender = testStepList => {
    const columns = [
      {
        key: FIELD_STEP,
        title: t('workItem.step'),
        dataIndex: FIELD_STEP,
        width: '25%',
        render: (val, row) => {
          return (
            <>
              {val?.value || ''}
              {renderError({ val, title: t('workItem.step') })}
            </>
          );
        }
      },
      {
        title: t('workItem.testData'),
        dataIndex: FIELD_TEST_DATA,
        key: FIELD_TEST_DATA,
        width: '25%',
        render: (val, row) => val?.value || ''
      },
      {
        title: t('workItem.expectedResult'),
        key: FIELD_EXPECTED_RESULT,
        dataIndex: FIELD_EXPECTED_RESULT,
        width: '25%',
        render: (val, row) => val?.value || ''
      },
      {
        title: t('workItem.precondition'),
        key: FIELD_TEST_PRECONDITION,
        dataIndex: FIELD_TEST_PRECONDITION,
        width: '25%',
        render: (val, row) => val?.value || ''
      }
    ];

    const newData = checkIsNotEmptyArray(testStepList) ? testStepList.map((s, index) => ({ ...s, index })) : [];

    return (
      <BasicTable
        rowKey="index"
        columns={columns}
        dataSource={newData}
        allowChangeQueryParamsUrl={false}
        pagination={false}
        bordered
        className="has-border-bottom-table-content"
        style={{ width: '95vw', margin: '10px' }}
      />
    );
  };

  /**
   * On show confirm
   */
  const onShowConfirm = async () => {
    const hasInvalidItems =
      checkIsNotEmptyArray(previewData?.rows) && previewData?.rows.some(item => item?.isInvalidRow);

    if (hasInvalidItems) {
      scrollToSelector({ selectorString: '.c-import-preview-modal .ant-alert-error', delay: 0 });
      await sleep(300);
    }

    Modal.confirm({
      title: <SafeInnerHtml html={t('message.areYouSureImport')} className="font-weight-normal" />,
      icon: <ExclamationCircleOutlined />,
      autoFocusButton: 'ok',
      okText: t(LANG_OK),
      okButtonProps: { type: 'primary' },
      cancelText: t(LANG_CANCEL),
      zIndex: 2000,
      onOk: () => {
        onImport(previewBody);
      },
      onCancel: () => {}
    });
  };

  /**
   * On change import option
   */
  const onChangeImportOption = val => {
    let newPreviewBody = {
      ...previewBody,
      page: 1,
      limit: PAGE_SIZE_10,
      changeType: val
    };

    setPreviewBody(newPreviewBody);
    onGetPreviewData(newPreviewBody);
  };

  return (
    <Modal
      open={visible}
      maskClosable={false}
      keyboard={false}
      width="100vw"
      zIndex={1004}
      footer={null}
      forceRender
      centered // For "modal-fixed-header"
      wrapClassName="modal-fixed-header" // Enable "centered" mode, wrap content by class "modal-body-with-scroll"
      onCancel={onCancel}
      className={`c-import-preview-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${className}`}
      {...rest}
    >
      <div className="px-4 pt-3">
        <Row align="middle" style={{ minHeight: 32 }}>
          <h3 className="text-primary font-weight-medium font-size-16 my-0 pr-4">
            <FolderViewOutlined /> {t('common.preview')}
          </h3>

          {workTicketId !== WORK_ITEM_TEST_RUN_ID && (
            <>
              <span className="font-weight-medium ml-3 mr-2">{t('common.chooseChangeType')}</span>
              <Select
                value={previewBody?.changeType}
                optionFilterProp="title"
                showSearch
                style={{ width: 100 }}
                disabled={loading}
                onSelect={val => onChangeImportOption(val)}
              >
                <Select.Option value={CHANGE_TYPE_IMPORT_BOTH} title={t('common.all')}>
                  {t('common.all')}
                </Select.Option>
                <Select.Option value={CHANGE_TYPE_IMPORT_CREATE} title={t('common.create')}>
                  {t('common.create')}
                </Select.Option>
                <Select.Option value={CHANGE_TYPE_IMPORT_UPDATE} title={t('common.update')}>
                  {t('common.update')}
                </Select.Option>
              </Select>
            </>
          )}
        </Row>

        <div className="mt-3 mb-0" />
      </div>

      <div className="modal-body-with-scroll">
        <div className="form-wrapper">
          <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loading}>
            {checkIsNotEmptyArray(dataSource) ? (
              <>
                <BasicTable
                  rowKey="index"
                  dataSource={dataSource}
                  columns={columnsPreview}
                  total={total}
                  currentPage={previewBody?.page}
                  currentLimit={previewBody?.limit}
                  expandable={
                    workTicketId === WORK_ITEM_TESTCASE_ID
                      ? {
                          expandedRowRender: record => expandedRowRender(record?.[SYSTEM_FIELD_TEST_STEPS]),
                          rowExpandable: record => checkIsNotEmptyArray(record?.[SYSTEM_FIELD_TEST_STEPS]),
                          defaultExpandAllRows: false
                        }
                      : null
                  }
                  bordered
                  allowChangeQueryParamsUrl={false}
                  onChange={info => {
                    const newPreviewBody = {
                      ...previewBody,
                      page: info?.pageSize !== previewBody?.limit ? 1 : info?.current,
                      limit: info?.pageSize
                    };

                    setPreviewBody(newPreviewBody);
                    onGetPreviewData(newPreviewBody);
                  }}
                />
              </>
            ) : (
              <Result status="warning" className="title-warning" title={t('template.canNotFindDataImport')} />
            )}
          </Spin>
        </div>
      </div>

      <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
        <Divider className="mt-0 mb-3" />

        <div className="text-right">
          <Button icon={<ArrowLeftOutlined />} onClick={onBack}>
            {t('common.previous')}
          </Button>

          <Button
            type="primary"
            icon={<SaveOutlined />}
            disabled={loading || !checkIsNotEmptyArray(previewData?.rows) || !checkIsNotEmptyArray(dataSource)}
            onClick={onShowConfirm}
          >
            {t('common.import')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

import { Button, Divider, Form, Modal, Row, Spin, Tooltip } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { SaveOutlined, FullscreenOutlined, FullscreenExitOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { arrayMoveImmutable } from 'array-move';

import {
  FIELD_SYSTEM,
  COMPONENT_TYPE,
  SYSTEM_FIELD_NAME,
  SS_NEW_TEST_STEPS,
  TESTMAN_PLATFORM_ID,
  SYSTEM_FIELD_ORDER_ID,
  WORK_ITEM_TESTCASE_ID,
  SYSTEM_FIELD_TEST_STEPS,
  SYSTEM_FIELD_ATTACHMENTS,
  SYSTEM_FIELD_DESCRIPTION,
  INTEGRATION_FROM_TEST_PLAN_FOLDER,
  INTEGRATION_FROM_REPOSITORY_FOLDER
} from '../../constants';
import {
  reactSessionStorage,
  checkIsNotEmptyArray,
  handleBeforeSubmitForm,
  scrollToFirstClassName
} from '../../common/utils';
import { useField, useFile } from '../../common/hooks';
import { BasicPasteUploadFile } from '../basic-paste-upload-file';
import { WorkItemField } from '../work-item-field';

const RelatedToAddWorkTicketModal = ({
  fromModule,
  visible,
  relation,
  className = '',
  onCancel,
  onPrevious,
  onSubmit,
  ...rest
}) => {
  const [t] = useTranslation('akaat');
  const [form] = Form.useForm();

  // For upload
  const { getUploadPath } = useFile();
  const { convertFormValuesToSubmitData } = useField();

  // For global store
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalProject = useStoreState(state => state.global.globalProject);
  const loadingObjectiveItem = useStoreState(state => state.global.loadingObjectiveItem);
  const fieldListData = useStoreState(state => state.global.fieldList);
  const loadingRelationItem = useStoreState(state => state.global.loadingRelationItem);
  const loadingFieldList = useStoreState(state => state.global.loadingFieldList);
  const getFieldList = useStoreActions(action => action.global.getFieldList);
  const globalProcessList = useStoreState(state => state.global.globalProcessList);
  const getFullFilesInfo = useStoreActions(action => action.global.getFullFilesInfo);
  const deleteFiles = useStoreActions(action => action.global.deleteFiles);

  // For store action
  const createTestCase = useStoreActions(action => action.testcase.createTestCase);
  const getRepositoryList = useStoreActions(action => action.repositoryTree.getRepositoryList);
  const createWorkTicket = useStoreActions(action => action.managerWorkItem.createWorkTicket);
  const loadingTestCaseItem = useStoreState(state => state.testcase.loadingItem);
  const loadingWorkTicketItem = useStoreState(state => state.managerWorkItem.loadingWorkTicketItem);

  // Component state
  const [workTicket, setWorkTicket] = useState({});
  const [currentTestStepList, setCurrentTestStepList] = useState([]);
  const [currentAttachmentList, setCurrentAttachmentList] = useState([]);
  const [deteledAttachmentIds, setDeteledAttachmentIds] = useState([]);
  const [currentEditorAttachFileList, setCurrentEditorAttachFiles] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [visibleFullScreenTooltip, setVisibleFullScreenTooltip] = useState(false);

  /**
   * Set work ticket
   */
  useEffect(() => {
    if (!Array.isArray(globalProcessList) || !globalProcessList.length || !relation?.workTicketType) {
      return;
    }

    const tickeType = globalProcessList.find(process => process?.workTicketId === relation.workTicketType);

    setWorkTicket(tickeType);
  }, [globalProcessList, relation]);

  /**
   * Compute: field list
   */
  const fieldList = useMemo(() => {
    if (!checkIsNotEmptyArray(fieldListData?.[relation?.workTicketType])) {
      return [];
    }

    const attachments = {
      name: 'Attachments',
      refName: SYSTEM_FIELD_ATTACHMENTS,
      componentType: COMPONENT_TYPE.ATTACHMENTS,
      type: FIELD_SYSTEM,
      isInsert: true
    };

    let newFieldList = [...fieldListData?.[relation?.workTicketType], attachments];

    if (newFieldList.some(field => field?.componentType === COMPONENT_TYPE.ATTACHMENTS)) {
      const oldIndex = newFieldList.findIndex(field => field?.componentType === COMPONENT_TYPE.ATTACHMENTS);
      const descriptionFieldIndex = newFieldList.findIndex(field => field?.refName === SYSTEM_FIELD_DESCRIPTION);

      newFieldList = arrayMoveImmutable(newFieldList, oldIndex, descriptionFieldIndex + 1);
    }

    return newFieldList;
  }, [fieldListData, relation?.workTicketType]);

  /**
   * Set initial primitive field
   */
  useEffect(() => {
    if (!relation) {
      return;
    }

    form.setFieldsValue({
      [SYSTEM_FIELD_NAME]: relation.item
    });
  }, [form, relation]);

  /**
   * Get field list and foder test case
   */
  useEffect(() => {
    if (relation?.workTicketType === WORK_ITEM_TESTCASE_ID) {
      getRepositoryList();
    }

    if (!fieldListData?.[relation?.workTicketType]) {
      getFieldList(relation?.workTicketType);
    }
  }, [relation?.workTicketType, fieldListData, getFieldList, getRepositoryList]);

  /**
   * On change current attachment list
   */
  const onChangeCurrentAttachmentList = async attachments => {
    await getFullFilesInfo(attachments);
    setCurrentAttachmentList(attachments);
  };

  /**
   * On attach file
   */
  const onEditorAttachFiles = attachments => {
    if (!(Array.isArray(attachments) && attachments.length)) {
      return;
    }

    const newList =
      Array.isArray(currentEditorAttachFileList) && currentEditorAttachFileList.length
        ? [...currentEditorAttachFileList, ...attachments]
        : [...attachments];

    setCurrentEditorAttachFiles(newList);
  };

  /**
   * loading
   */
  const loading = useMemo(() => {
    return loadingFieldList || loadingRelationItem || loadingWorkTicketItem || loadingObjectiveItem;
  }, [loadingFieldList, loadingRelationItem, loadingWorkTicketItem, loadingObjectiveItem]);

  /**
   * Check no action when submit
   */
  const checkNoActionWhenSubmit = ({ relation, values, loadingWorkTicketItem, loadingTestCaseItem }) => {
    return !relation?.relationType || !values || loadingWorkTicketItem || loadingTestCaseItem;
  };

  /**
   * Get test steps for submit data
   */
  const getTestStepsForSubmitData = ({ sessionTestStepList, currentTestStepList }) => {
    const newSessionTestStepList =
      Array.isArray(sessionTestStepList) && sessionTestStepList.length ? [...sessionTestStepList] : [];

    const testStepList =
      Array.isArray(currentTestStepList) && currentTestStepList.length ? [...currentTestStepList] : [];

    const newList = [...testStepList, ...newSessionTestStepList].map((s, idx) => {
      const newStep = { ...s, [SYSTEM_FIELD_ORDER_ID]: idx + 1 };
      delete newStep.key;
      delete newStep.isNew;
      return newStep;
    });

    return newList;
  };

  /**
   * Get attachments for submit data
   */
  const getAttachmentsForSubmitData = ({ currentAttachmentList, currentEditorAttachFileList }) => {
    const attachmentList =
      Array.isArray(currentAttachmentList) && currentAttachmentList.length ? [...currentAttachmentList] : [];

    const editorAttachFileList =
      Array.isArray(currentEditorAttachFileList) && currentEditorAttachFileList.length
        ? [...currentEditorAttachFileList]
        : [];

    return [...attachmentList, ...editorAttachFileList];
  };

  /**
   * On search
   */
  const hanldeSubmit = async values => {
    if (checkNoActionWhenSubmit({ relation, values, loadingWorkTicketItem, loadingTestCaseItem })) {
      return;
    }

    let formData = {
      workTicketType: relation?.workTicketType
    };

    const newFormData2 = convertFormValuesToSubmitData({ formValues: values, fieldList });
    formData = { ...formData, ...newFormData2 };

    // Test steps
    const newTestStepsInSession = reactSessionStorage.getObject(SS_NEW_TEST_STEPS, {});
    const sessionTestStepList = newTestStepsInSession['new'];
    formData[SYSTEM_FIELD_TEST_STEPS] = getTestStepsForSubmitData({ sessionTestStepList, currentTestStepList });

    // Attachments
    formData[SYSTEM_FIELD_ATTACHMENTS] = getAttachmentsForSubmitData({
      currentAttachmentList,
      currentEditorAttachFileList
    });

    submitUtils({
      formData,
      fromModule,
      relation,
      deteledAttachmentIds,
      globalTenant,
      globalProject
    });
  };

  /**
   * Submit utils
   */
  const submitUtils = async ({ formData, fromModule, relation, deteledAttachmentIds, globalTenant, globalProject }) => {
    let res = null;

    if (relation?.workTicketType === WORK_ITEM_TESTCASE_ID) {
      const resTestCase = await createTestCase(formData);
      res = resTestCase?.data;
    } else {
      res = await createWorkTicket(formData);
    }

    if (res?.key) {
      const formData = { ...res };

      if (fromModule === INTEGRATION_FROM_REPOSITORY_FOLDER || fromModule === INTEGRATION_FROM_TEST_PLAN_FOLDER) {
        formData.integrationSystem = TESTMAN_PLATFORM_ID;
      }

      onSubmit(formData);
    }

    // Handle delete multiple attachments
    if (Array.isArray(deteledAttachmentIds) && deteledAttachmentIds.length) {
      deleteFiles({ globalTenant, globalProject, ids: [...deteledAttachmentIds] });
      setDeteledAttachmentIds([]);
    }
  };

  return (
    <>
      <Modal
        open={visible}
        width={1160}
        maskClosable={false}
        keyboard={false}
        footer={null}
        forceRender
        centered // For "modal-fixed-header"
        wrapClassName="modal-fixed-header"
        className={`c-related-to-add-work-ticket-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${
          fullScreen ? 'full-screen-modal' : ''
        } ${className}`}
        onCancel={onCancel}
        {...rest}
      >
        <div className="px-4 pt-3">
          <Row justify="space-between" align="middle">
            <h3 className="text-primary font-weight-medium font-size-16 m-0">
              {t('relationTo.relateToSomething', { name: workTicket?.name })}
            </h3>

            <Row align="middle" justify="end" wrap={false} className="box-extra">
              <Tooltip
                open={visibleFullScreenTooltip}
                title={fullScreen ? t('common.exitFullScreen') : t('common.fullScreen')}
                placement={fullScreen ? 'bottomRight' : 'bottom'}
                destroyTooltipOnHide={true}
                overlayStyle={{ pointerEvents: 'none' }}
                onOpenChange={setVisibleFullScreenTooltip}
              >
                <Button
                  icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                  type="link"
                  className="text-gray-2 text-hover-primary border-0 bg-transparent w-auto h-auto p-0 ml-3"
                  onClick={() => {
                    setFullScreen(!fullScreen);
                    setVisibleFullScreenTooltip(false);
                  }}
                />
              </Tooltip>
            </Row>
          </Row>

          <Divider className="mt-3 mb-0" />
        </div>

        <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
          <div className="form-wrapper p-4">
            <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loading}>
              <Form
                id="form-add-work-ticket"
                form={form}
                layout="vertical"
                onFinish={value => {
                  setTimeout(() => scrollToFirstClassName('ant-form-item-has-error', 0), 300);
                  hanldeSubmit(value);
                }}
              >
                {checkIsNotEmptyArray(fieldList) &&
                  fieldList
                    .filter(field => field?.isInsert)
                    .map((field, index) => {
                      return (
                        <WorkItemField
                          workTicketId={relation?.workTicketType}
                          key={index}
                          form={form}
                          field={field}
                          currentTestStepList={currentTestStepList}
                          onChangeCurrentTestStepList={setCurrentTestStepList}
                          showRepositoryFolderField={true}
                          uploadPath={getUploadPath(relation?.workTicketType)}
                          currentAttachmentList={currentAttachmentList}
                          onChangeCurrentAttachmentList={setCurrentAttachmentList}
                          onChangeDeteledAttachmentIds={ids =>
                            setDeteledAttachmentIds([...deteledAttachmentIds, ...ids])
                          }
                          onEditorAttachFiles={onEditorAttachFiles}
                        />
                      );
                    })}
              </Form>
            </Spin>
          </div>
        </div>

        <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
          <Divider className="mt-0 mb-3" />

          <div className="text-right">
            <Button onClick={onPrevious}>{t('common.previous')}</Button>

            <Button
              form="form-add-work-ticket"
              htmlType="submit"
              className="ml-2"
              type="primary"
              icon={<SaveOutlined />}
              onClick={() => handleBeforeSubmitForm(form)}
            >
              {t('common.save')}
            </Button>
          </div>
        </div>
      </Modal>

      {visible && (
        <BasicPasteUploadFile
          attachments={currentAttachmentList}
          acceptMineTypes={['image/']}
          uploadPath={getUploadPath(relation?.workTicketType)}
          onChangeAttachments={onChangeCurrentAttachmentList}
        />
      )}
    </>
  );
};

export default RelatedToAddWorkTicketModal;
